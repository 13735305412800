import React, { Fragment, useState } from 'react';
import * as SocketConnection from '../utils/SocketConnection';
import PolyRenderer from './PolyRenderer';
import ControlButtons from './ControlButtons';
import LoadingChecker from './LoadingChecker';
import '../css/Dashboard.css';
import Navbar from '../components/Navbar';
import '../css/custom_button.scss';
const Dashboard = () => {
	const identifier = 'DASHBOARD';
	//if no username or password on loading checker, then direct login page.
	//if username, password in local storage, attempt tcp connect and login. direct dashboard on success, login page on fail.
	const [isDashboardReady, setIsDashboardReady] = useState(false);
	const [credit, setCredit] = useState(0);
	const [connections, setConnections] = useState(0);
	const [serverAddress, setServerAddress] = useState(null);

	React.useEffect(() => {
		if (isDashboardReady) {
			//may need to also check if can progress dashbaord
			//we are authorized, attempt connect to tcp server and poly server
			SocketConnection.addEventSubscriber(identifier, handleControlUpdates);
			SocketConnection.requestBasicData();
			if (SocketConnection.getUserServerAddress()) {
				if (SocketConnection.getUserServerAddress() == '127.0.0.1')
					setServerAddress('LOCAL');

				if (SocketConnection.getUserServerAddress() == '18.192.0.15')
					setServerAddress('EU WEST');

				if (SocketConnection.getUserServerAddress() == '44.209.176.109')
					setServerAddress('US EAST');

					
				if (SocketConnection.getUserServerAddress() == '122.58.123.195')
					setServerAddress('US EAST 2');

				//old is 52.64.106.120
				if (SocketConnection.getUserServerAddress() == '52.62.216.107')
					setServerAddress('AUSTRALIA');
			}

			//render the control buttons, credit, etc
		}
	}, [isDashboardReady, serverAddress]);

	function handleControlUpdates(data) {
		if (data.includes('AVAILABLE_CREDIT')) {
			const balance = data.split('AVAILABLE_CREDIT:')[1].split('::END')[0];
			setCredit(balance);
		}
		if (data.includes('ACTIVE_CONNECTIONS')) {
			const connections = data
				.split('ACTIVE_CONNECTIONS:')[1]
				.split('::END')[0];
			setConnections(connections);
		}
	}

	if (!isDashboardReady || !serverAddress) {
		return <LoadingChecker callback={readyCallback} />;
	}

	return (
		<Fragment>
			<Navbar serverAddress={serverAddress} />

			<PolyRenderer data={connections} />
		</Fragment>
	);

	function readyCallback(status) {
		if (status.error === false) {
			setIsDashboardReady(true);
		}
	}
};
export default Dashboard;
