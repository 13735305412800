import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as SocketConnection from '../utils/SocketConnection';
import Loading from '../components/Loading';
import ErrorSplash from './ErrorSplash';

const LoadingChecker = (props) => {
	const history = useHistory();
	const [isValidToken, setIsValidToken] = useState(null);
	const [isRegisteredController, setIsRegisteredController] = useState(null);
	const [proxyFail, setProxyFail] = useState(null);
	const [tcpFail, setTcpFail] = useState(null);
	const [restServerFail, setRestServerFail] = useState(null);
	const mounted = useRef(false);
	const [isLoaded, setIsLoaded] = useState(false);

	React.useEffect(() => {
		// on dashboard attempt load
		mounted.current = true;
		//load in locals
		SocketConnection.setPassword(localStorage.getItem('password'));
		SocketConnection.setEmail(localStorage.getItem('email'));
		//if these are not available, go to login
		if (!SocketConnection.getPassword() || !SocketConnection.getEmail()) {
			return history.push('/login');
		}

		SocketConnection.createAuthorizedConnection(checkIfCanProgress);

		if (isValidToken && isRegisteredController) {
	
			setIsLoaded(true);
			return props.callback({ error: false });
		}
		return () => {
			mounted.current = false;
		};
	}, [
		isValidToken,
		isRegisteredController,
		proxyFail,
		tcpFail,
		restServerFail,
		isLoaded,
		history,
		props,
	]);

	if (isValidToken === false) {
		return history.push(`/login`);
	}
	if (isRegisteredController === false && isValidToken) {
		props.callback({ error: true });
		return <ErrorSplash message="Dashboard Connection failure" />;
	}
	if (proxyFail === true) {
		props.callback({ error: true });
		return <ErrorSplash message="Server proxy connection failure" />;
	}
	if (tcpFail === true) {
		props.callback({ error: true });
		return <ErrorSplash message="Server connection failure" />;
	}
	if (restServerFail === true) {
		props.callback({ error: true });
		return <ErrorSplash message="API connection failure" />;
	}

	if (isLoaded) {
		return null;
	} else {
		return <Loading />;
	}
	function checkIfCanProgress(data) {
		if (!mounted.current) return null;
		if (data.redirectLogin) {
			console.log('Redirecting to login');
			return history.push('/login');
		}
		if (data.isValidToken !== undefined) {
			console.log('Valid token baby!');
			return setIsValidToken(data.isValidToken);
		}
		if (data.isRegisteredController !== undefined)
			return setIsRegisteredController(data.isRegisteredController);
		if (data.proxyFail !== undefined) return setProxyFail(data.proxyFail);
		if (data.tcpFail !== undefined) return setTcpFail(data.tcpFail);
		if (data.restServerFail !== undefined)
			return setRestServerFail(data.restServerFail);
	}
};
export default LoadingChecker;
