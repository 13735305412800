import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import './App.css';
import { Container } from "reactstrap";
import ErrorSplash from './views/ErrorSplash';
import Dashboard from './views/Dashboard';
import Login from './views/Login';
import CredentialSetter from "./views/CredentialSetter"
import PolyRenderer from './views/PolyRenderer';
function App() {
  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">

        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/connect/:email/:password" exact component={CredentialSetter} />
            <Route path="/" exact component={Dashboard} />
            <Route path="/error" exact component={ErrorSplash} />
            <Route path="/login" exact component={Login} />
            <Route path="/viewer" exact component={PolyRenderer} />
          </Switch>
        </Container>
      </div>
    </Router>
  );
};
export default App;
