import React, { Fragment, useState } from 'react';
import * as SocketConnection from '../utils/SocketConnection';
import fetch from 'node-fetch';
import config from '../config.json';
import { useHistory } from 'react-router-dom';
import '../css/Dashboard.css';
import osboxLogo from '../assets/osbox.png';
import ErrorSplash from './ErrorSplash';
import PolyRenderer from './PolyRenderer';
import ControlButtons from './ControlButtons';
import LoadingChecker from './LoadingChecker';
import Navbar from '../components/Navbar';

let email = null;
let password = null;

const Login = () => {
	const history = useHistory();
	let emailInput = React.createRef();
	let passwordInput = React.createRef();

	const [loginErrorMessage, setLoginErrorMessage] = useState('');
	React.useEffect(() => {}, [history]);

	async function handleLoginAction() {
		email = emailInput.current.value;
		password = passwordInput.current.value;
		if (!email) return setLoginErrorMessage('Email is required');
		if (!password) return setLoginErrorMessage('Password is required');
		const isAuthorized = await sendLoginRequest(email, password);

		if (isAuthorized) {
			SocketConnection.setPassword(password);
			SocketConnection.setEmail(email);
			return history.push('/');
		}
		//display invalid login
		return setLoginErrorMessage('INVALID LOGIN CREDENTIALS');
	}

	async function sendLoginRequest(email, password) {
		const body = {
			email,
			password,
		};
		const response = await fetch(config.REST_API_ENDPOINT + '/login', {
			method: 'POST',
			body: JSON.stringify(body),
			headers: { 'Content-Type': 'application/json' },
		});
		const result = await response.json();
	
		return result.success;
	}

	return (
		<Fragment>
			<div className="login-bg-container">
				<div className="login-section-container">
					<div className="login-section">
						<div className="login-error">{loginErrorMessage}</div>

						<img className="login-logo" src={osboxLogo}></img>
						<form>
							<label>
								OSBox Credential Email
								<input ref={emailInput} type="text" name="email" required />
							</label>
							<br></br>
							<label>
								OSBox Credential Password
								<input
									ref={passwordInput}
									type="password"
									name="password"
									required
								/>
							</label>
							<br></br>

							<button
								type="button"
								className="dashboard-main-button"
								onClick={handleLoginAction}
							>
								Submit
							</button>
						</form>
					</div>
				</div>
				<div className="disabled-dashboard-bg">
					<Navbar serverAddress={null} />

					<PolyRenderer data={null} />
				</div>
			</div>
		</Fragment>
	);
};
export default Login;
