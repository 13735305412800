import * as PolySocketConnection from '../utils/PolySocketConnection';
import * as SocketConnection from '../utils/SocketConnection';
import * as SyncStatusChecker from '../utils/SyncStatusChecker';
import React, { Fragment, useState } from 'react';
import Popout from 'react-popout';
import '../css/PolyRenderer.scss';
import ErrorSplash from './ErrorSplash';
import loginScreen from '../assets/Login_screen.png';

import PolyGrid from '../components/PolyGrid';
import fetch from 'node-fetch';
import TopMapToolbar from '../components/TopMapToolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faGripHorizontal,
	faTh,
	faThLarge,
	faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
const PolyRenderer = (props) => {
	const [connections, setConnections] = useState(0);
	const [grid_size, setGridSize] = useState('medium-view');
	const [dashboardLayoutMode, setDashboardLayoutMode] = useState(0);

	let clients = {
		0: {},
		1: {},
		2: {},
		3: {},
		4: {},
		5: {},
		6: {},
		7: {},
		8: {},
		9: {},
		10: {},
		11: {},
		12: {},
		13: {},
		14: {},
		15: {},
		16: {},
		17: {},
		18: {},
		19: {},
		20: {},
		21: {},
		22: {},
		23: {},
		24: {},
		25: {},
		26: {},
		27: {},
		28: {},
		29: {},
	};
	let dummyClients = [
		{ index: 0 },
		{ index: 1 },
		{ index: 2 },
		{ index: 3 },
		{ index: 4 },
		{ index: 5 },
		{ index: 6 },
		{ index: 7 },
		{ index: 8 },
		{ index: 9 },
		{ index: 10 },
		{ index: 11 },
		{ index: 12 },
		{ index: 13 },
		{ index: 14 },
		{ index: 15 },
		{ index: 16 },
		{ index: 17 },
		{ index: 18 },
		{ index: 19 },
		{ index: 20 },
		{ index: 21 },
		{ index: 22 },
		{ index: 23 },
		{ index: 24 },
		{ index: 25 },
		{ index: 26 },
		{ index: 27 },
		{ index: 28 },
		{ index: 29 },
	];
	const [polyClients, setPolyClients] = useState([]);

	const [worldMapSrc, setWorldMapSrc] = useState('');
	const [mapPosition, setMapPosition] = useState({});

	const [popOutRequested, setPopOutRequested] = useState(false);

	const [isKickedOffDashboardError, setIsKickedOffDashboardError] =
		useState(false);

	const [isConnectionError, setIsConnectionError] = useState(false);
	const [hasAttemptedPolyConnection, setHasAttemptedPolyConnection] =
		useState(false);
	React.useEffect(() => {
		SyncStatusChecker.checkPositionSyncStatus(polyClients);
		SyncStatusChecker.checkWidgetOpenSyncStatus(polyClients);
		SyncStatusChecker.checkLoginStateSyncStatus(polyClients);
		SyncStatusChecker.checkInventoryMatchesSyncStatus(polyClients);
		SyncStatusChecker.checkEquipmentMatchesSyncStatus(polyClients);
		SyncStatusChecker.checkDialogueMatchesSyncStatus(polyClients);
		//send sync status to tcp, which sends to master.
		SocketConnection.sendSyncNotifierStatus(SyncStatusChecker.getIsOutOfSync());
		if (!hasAttemptedPolyConnection) {
			setHasAttemptedPolyConnection(true);
			createPolyConnection();
			setPolyClients(dummyClients);
		}

		setConnections(props.data);
	}, [
		polyClients,
		popOutRequested,
		isConnectionError,
		isKickedOffDashboardError,
		connections,
		hasAttemptedPolyConnection,
	]);

	function createPolyConnection() {
		PolySocketConnection.createAuthorizedConnection(
			polyConnectionResult,
			polyRenderResult
		);
	}

	function polyConnectionResult(err) {
		if (err) {
			console.log(err);
			if (err == 'END_SESSION') {
				return setIsKickedOffDashboardError(true);
			}
			setHasAttemptedPolyConnection(false);
			return setIsConnectionError(true);
		}

		setIsConnectionError(false);
		SocketConnection.requestPolyData();
	}

	function polyRenderResult(data, closeClientPreview) {
		// Base64 string data

		const { poly_data, client_index } = data;

		if (closeClientPreview) {
			console.log('DELETING');
			delete clients[client_index];
			setPolyClients(Object.values(clients));
			//remove from minimap
			return;
		}

		if (
			poly_data !== 'LOGIN_SCREEN' &&
			poly_data !== 'LOGGING_IN_SCREEN' &&
			poly_data !== 'STARTING_UP'
		) {
			console.log(poly_data);
			const statusInfoData = poly_data
				.split('INFO_SEGMENT:::')[1]
				.split(':::POLY_SEGMENT:::')[0];
			const displayName = statusInfoData.split('NAME:::')[1].split(':::')[0];
			const hitpoints = statusInfoData.split('HEALTH:::')[1].split(':::')[0];
			const maxHitpoints = statusInfoData
				.split('MAX_HEALTH:::')[1]
				.split(':::')[0];
			const prayerPoints = statusInfoData.split('PRAYER:::')[2].split(':::')[0];
			const maxPrayerPoints = statusInfoData
				.split('MAX_PRAYER:::')[1]
				.split(':::')[0];
			const runEnergy = statusInfoData.split('RUN:::')[1].split(':::')[0];
			const absorptionPoints = statusInfoData
				.split('ABSORPTION:::')[1]
				.split(':::')[0];
			const worldLocation = statusInfoData
				.split('LOCATION:::')[1]
				.split(':::')[0];

			let chatDialogue = statusInfoData
				.split('DIALOGUE:::')[1]
				.split(':::')[0]
				.replaceAll(displayName, '');
			if (chatDialogue.includes('OPTION:')) {
				const options = chatDialogue.split('OPTION:').sort().join('');
				chatDialogue = options;
			}

			const isWidgetOpen = statusInfoData
				.split('WIDGET_OPEN:::')[1]
				.split(':::')[0];
			const isLoggedIn = statusInfoData
				.split('IS_LOGGED_IN:::')[1]
				.split(':::')[0];
			const animation = statusInfoData.split('ANIMATION:::')[1].split(':::')[0];
			const inventoryItemString = statusInfoData
				.split('INVENTORY:::')[1]
				.split(':::')[0]
				.split('ITEM');

			const equipmentItemString = statusInfoData
				.split('EQUIPMENT:::')[1]
				.split(':::')[0]
				.split('ITEM');

			let inventoryItems = [];
			for (const inventoryItem of inventoryItemString) {
				const itemId = inventoryItem.split(':')[0];
				const itemQuantity = inventoryItem.split(':')[1];
				if (itemId == -1) continue;

				inventoryItems.push({ itemId, itemQuantity });
			}

			let equipmentItems = [];
			for (const equipmentItem of equipmentItemString) {
				const itemId = equipmentItem.split(':')[0];
				const itemQuantity = equipmentItem.split(':')[1];
				if (itemId == -1) continue;

				equipmentItems.push({ itemId, itemQuantity });
			}

			const worldLocationX = worldLocation.split(':')[3];
			const worldLocationY = worldLocation.split(':')[4];

			let mapLocationX = worldLocation.split(':')[0];
			let mapLocationY = worldLocation.split(':')[1];
			const mapLocationZ = worldLocation.split(':')[2];

			if (client_index == 0) {
				if (mapLocationX == -1) mapLocationX = 50;
				if (mapLocationY == -1) mapLocationY = 53;

				setWorldMapSrc({
					q1: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${
						mapLocationX - 1
					}_${parseInt(mapLocationY) + 1}.png`,
					q2: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${mapLocationX}_${
						parseInt(mapLocationY) + 1
					}.png`,
					q3: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${
						parseInt(mapLocationX) + 1
					}_${parseInt(mapLocationY) + 1}.png`,
					q4: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${
						parseInt(mapLocationX) - 1
					}_${mapLocationY}.png`,
					q5: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${mapLocationX}_${mapLocationY}.png`,
					q6: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${
						parseInt(mapLocationX) + 1
					}_${mapLocationY}.png`,
					q7: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${
						mapLocationX - 1
					}_${parseInt(mapLocationY) - 1}.png`,
					q8: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${mapLocationX}_${
						parseInt(mapLocationY) - 1
					}.png`,
					q9: `https://mejrs.github.io/layers_osrs/mapsquares/-1/2/0_${
						parseInt(mapLocationX) + 1
					}_${parseInt(mapLocationY) - 1}.png`,
				});
			} else if (client_index === 0) {
				setWorldMapSrc({});
			}

			const imageData = poly_data.split(':::POLY_SEGMENT:::')[1];

			let inventorySrc;
			let gameScreenSrc;
			let chatboxSrc;
			if (imageData.length > 1000) {
				//image data has at least 1k
				let inventoryPolyData = imageData
					.split('INVENTORY:::')[1]
					.split(':::GAMESCREEN:::')[0];
				let gameScreenPolyData = imageData
					.split(':::GAMESCREEN:::')[1]
					.split(':::CHATBOX:::')[0];
				let chatboxPolyData = imageData.split(':::CHATBOX:::')[1];

				inventorySrc = 'data:image/jpg;base64,' + inventoryPolyData;
				gameScreenSrc = 'data:image/jpg;base64,' + gameScreenPolyData;
				chatboxSrc = 'data:image/jpg;base64,' + chatboxPolyData;
			}
			//console.log('FOR CLIENT ' + client_index);

			console.log(maxPrayerPoints + ':' + prayerPoints);
			const client = {
				index: client_index,
				statsInfo: {
					displayName,
					maxHitpoints,
					hitpoints,
					maxPrayerPoints,
					prayerPoints,
					runEnergy,
					absorptionPoints,
				},
				syncInfo: {
					isWidgetOpen,
					inventoryItems,
					equipmentItems,
					chatDialogue,
					isLoggedIn,
					playerAnimation: animation,
				},
				worldLocationX,
				worldLocationY,
				mapLocationX,
				mapLocationY,
				mapLocationZ,
				inventorySrc: inventorySrc || clients[client_index].inventorySrc,
				gameScreenSrc: gameScreenSrc || clients[client_index].gameScreenSrc,
				chatboxSrc: chatboxSrc || clients[client_index].chatboxSrc,
			};
			// console.log("for client index " + client_index)
			// console.log(isLoggedIn)
			console.log(displayName);

			clients[client_index] = client;

			setPolyClients(Object.values(clients));
			if (
				client.statsInfo.displayName !== 'null' &&
				client.statsInfo.displayName !== '?'
			) {
				if (!SyncStatusChecker.syncList[client.statsInfo.displayName]) {
					SyncStatusChecker.syncList[client.statsInfo.displayName] = {
						displayName: client.statsInfo.displayName,
						notifiedSyncLoss: false,
						notifiedSyncLossTime: null,
						isOutOfSync: false,
					};
				}
			}
		} else {
			//login screen

			const client = {
				index: client_index,
				loginScreenSrc: loginScreen,
			};

			clients[client_index] = client;

			setPolyClients(Object.values(clients));
		}
	}

	function requestPopOutPoly() {
		setPopOutRequested(true);

		//send to tcp server the poly client should emit command via socketio proxy
	}

	function closePopOutPoly() {
		setPopOutRequested(false);
		//send to tcp server the poly client should no emit command via socketio proxy
	}

	let error;
	if (isConnectionError) {
		error = (
			<ErrorSplash message="Multibox viewing server connection failure" />
		);
	}
	if (isKickedOffDashboardError) {
		error = (
			<ErrorSplash message="DISCONNECTED. Another dashboard has connected." />
		);
	}
	let polyWindow;
	if (popOutRequested) {
		polyWindow = (
			<Popout
				url="polyRenderer"
				title="Window title"
				onClosing={closePopOutPoly}
			>
				<div className="popout-poly-viewer-container">
					<div id="popout-polyViewer">
						{polyClients.map((client) => {
							return <PolyGrid grid_size={grid_size} client={client} />;
						})}
					</div>
				</div>
			</Popout>
		);
	}

	return (
		<Fragment>
			{polyWindow}
			{error}
			<div className="poly-viewer-container">
				<div id="polyViewer">
					{dashboardLayoutMode == 0 && (
						<TopMapToolbar
							polyClients={polyClients}
							worldMapSrc={worldMapSrc}
							data={props}
							requestPopOutPoly={requestPopOutPoly}
						/>
					)}

					<div className="poly-account-dashboard-container">
						<div className="poly-grid-toggle-toolbar-container">
							<div className="button-group">
								<button
									onClick={() => {
										setGridSize('small-view');
									}}
									className="dashboard-main-button grid-button-toggler"
								>
									{' '}
									<FontAwesomeIcon icon={faTh} />
								</button>
								<button
									onClick={() => {
										setGridSize('medium-view');
									}}
									className="dashboard-main-button grid-button-toggler"
								>
									<FontAwesomeIcon icon={faGripHorizontal} />
								</button>
								<button
									onClick={() => {
										setGridSize('large-view');
									}}
									className="dashboard-main-button grid-button-toggler"
								>
									<FontAwesomeIcon icon={faThLarge} />
								</button>
							</div>
							<div className="button-group">
								<button
									onClick={() => {
										setDashboardLayoutMode(dashboardLayoutMode === 0 ? 1 : 0);
									}}
									className="dashboard-main-button grid-button-toggler"
								>
									<FontAwesomeIcon icon={faExchangeAlt} />
								</button>
							</div>
							{/* <div className="button-group">
								<button className="dashboard-main-button"></button>
								<button className="dashboard-main-button"></button>
							</div> */}
						</div>

						<div className="poly-sub-container">
							<div className="poly-account-dashboard">
								{polyClients.map((client) => {
									return <PolyGrid grid_size={grid_size} client={client} />;
								})}
							</div>
						</div>
					</div>
					{dashboardLayoutMode == 1 && (
						<TopMapToolbar
							polyClients={polyClients}
							worldMapSrc={worldMapSrc}
							data={props}
							requestPopOutPoly={requestPopOutPoly}
						/>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default PolyRenderer;
