import React from "react";
import { useHistory } from "react-router-dom";
import * as SocketConnection from "../utils/SocketConnection"
import {
    useParams
} from "react-router-dom";
const TokenSetter = () => {
    const history = useHistory();
    const { password, email } = useParams(); //region in form of US_EAST, EU_WEST etc.
    React.useEffect(() => {
        SocketConnection.setPassword(password)
        SocketConnection.setEmail(email)
        history.push("/")
    })
    return null
}

export default TokenSetter;
