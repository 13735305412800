import React, { Fragment, useState } from 'react';
import * as SyncStatusChecker from '../utils/SyncStatusChecker';
import prayerIcon from '../assets/Prayer_icon.png';
import hitpointsIcon from '../assets/Hitpoints_icon.png';
import mapMarkerIcon from '../assets/map-marker-icon.png';
import osBoxLogo from '../assets/osbox-slave-marker.png';
import positionLogo from '../assets/position_icon.png';
import logoutLogo from '../assets/Logout.png';
import inventoryLogo from '../assets/Inventory_icon.png';
import equipmentLogo from '../assets/equipment_icon2.png';
import chatboxLogo from '../assets/chatbox_icon.png';
import widgetLogo from '../assets/widget_icon2.png';
import ControlButtons from '../views/ControlButtons';
const TopMapToolbar = (props) => {
	const { polyClients, worldMapSrc, data, requestPopOutPoly } = props;
	const [connections, setConnections] = useState(0);
	const [requestPopOutPolyMethod, setRequestPopOutPolyMethod] = useState(null);
	const [echoStatus, setEchoStatus] = useState(false);

	React.useEffect(() => {
		setRequestPopOutPolyMethod(data.requestPopOutPoly);
		setConnections(data.data);
	}, [connections, requestPopOutPolyMethod, echoStatus]);
	function calculateMapLeft(clients) {
		for (const key in clients) {
			const index = clients[key].index;

			if (index == 0) {
				// return x;
				//every tile is 4px.
				//if we want
				return (
					-460 +
					380 -
					((clients[key].worldLocationX > -1
						? clients[key].worldLocationX
						: 12) %
						64) *
						4
				); //minimap is 500px so this will center
			}
		}
	}
	function callbackSetEchoStatus(status) {
		setEchoStatus(status);
		SyncStatusChecker.setEchoStatus(status);
	}
	function calculateMapTop(clients) {
		for (const key in clients) {
			const index = clients[key].index;
			if (index == 0) {
				let y = clients[key].worldLocationY / 36;

				return (
					-460 +
					115 +
					((clients[key].worldLocationY > -1
						? clients[key].worldLocationY
						: 40) %
						64) *
						4
				);
			}
			//NOTE FOR FUTURE IFWE EVBER ADJUST MAP SIZE, ADJUST THE 420 CONSTANT
			//(384 - 128 + 255 - (y % 64) * 4)
			//minimap is 500px so this will center
		}
	}
	function getAccountLocationStyle(x, y, client) {
		let moddedModulo = y % 64;
		let calcY = 384 - 128 + 255 - moddedModulo * 4 - 5;
		let calcX = 384 - 128 + (x % 64) * 4 - 5;
		if (client.index != 0) {
			const masterMapTileY = polyClients[0].mapLocationY;
			const masterMapTileX = polyClients[0].mapLocationX;
			if (masterMapTileY - client.mapLocationY > 0) {
				calcY += 255;
			} else if (masterMapTileY - client.mapLocationY < 0) {
				calcY -= 255;
			}

			if (masterMapTileX - client.mapLocationX > 0) {
				calcX -= 255;
			} else if (masterMapTileX - client.mapLocationX < 0) {
				calcX += 255;
			}
		}
		if (isNaN(calcY)) calcY = 0;
		if (isNaN(calcX)) calcX = 0;
		const styleData = {
			top: calcY - 10, //5px indicator
			left: calcX - 5,
			// backgroundColor: '#ff3636',
			borderRadius: '100%',
			padding: '12px',
			backgroundImage: `url(${osBoxLogo})`,
			backgroundSize: '100%',
		};
		// if (client.index == 0) {
		// 	styleData.backgroundImage = `url(${mapMarkerIcon})`
		// 	styleData.backgroundSize = '100%';
		// 	styleData.padding = '20px';
		// 	styleData.top = calcY - 40;
		// 	styleData.left = calcX - 16
		// }
		return styleData;
	}
	const mapPositionStyle = {
		top: calculateMapTop(polyClients),
		left: calculateMapLeft(polyClients),
	};
	return (
		<div className="top-toolbar-container">
			<div className="map-section-container">
				{SyncStatusChecker.getIsOutOfSync() && SyncStatusChecker.isEnabled && (
					<div className="minimap-out-of-sync-overlay"></div>
				)}
				<div className="flex out-of-sync-image-container">
					{SyncStatusChecker.getIsOutOfLoginSync() && (
						<img className="out-of-sync-image" src={logoutLogo}></img>
					)}
					{!SyncStatusChecker.getIsOutOfLoginSync() &&
						SyncStatusChecker.getIsOutOfPositionSync() && (
							<img className="out-of-sync-image" src={positionLogo}></img>
						)}
					{SyncStatusChecker.getIsOutOfInventorySync() && (
						<img className="out-of-sync-image" src={inventoryLogo}></img>
					)}
					{SyncStatusChecker.getIsOutOfEquipmentSync() && (
						<img className="out-of-sync-image" src={equipmentLogo}></img>
					)}
					{SyncStatusChecker.getIsOutOfChatDialogueSync() && (
						<img className="out-of-sync-image" src={chatboxLogo}></img>
					)}
					{SyncStatusChecker.getIsOutOfWidgetSync() && (
						<img className="out-of-sync-image" src={widgetLogo}></img>
					)}
				</div>
				{/* {echoStatus && <div className="map-view-border"></div>} */}
				<div className="map-view">
					<div className="map-section" style={mapPositionStyle}>
						{polyClients.map((client) => {
							return (
								<div
									key={client.index}
									className={`map-account-icon ${
										!client.worldLocationX ||
										client.worldLocationX == -1 ||
										!client.worldLocationY ||
										client.worldLocationY == -1
											? 'no-icon'
											: ''
									}`}
									style={getAccountLocationStyle(
										client.worldLocationX,
										client.worldLocationY,
										client
									)}
								></div>
							);
						})}

						<img src={worldMapSrc.q1}></img>
						<img src={worldMapSrc.q2}></img>
						<img src={worldMapSrc.q3}></img>
						<img src={worldMapSrc.q4}></img>
						<img src={worldMapSrc.q5}></img>
						<img src={worldMapSrc.q6}></img>
						<img src={worldMapSrc.q7}></img>
						<img src={worldMapSrc.q8}></img>
						<img src={worldMapSrc.q9}></img>
					</div>
				</div>
			</div>
			{/* <div className="gradient-border gradient-bg"></div> */}
			<div className="button-info-section-container">
				{/* <h3>Connected Clients: {connections}</h3> */}
				{/* <p>{credit}</p> */}

				<ControlButtons setCallbackEcho={callbackSetEchoStatus} />
				{/* <button type="button" onClick={requestPopOutPolyMethod}>
					Pop Out Display
				</button> */}
			</div>
		</div>
	);
};

export default TopMapToolbar;
