import React, { Fragment, useState } from 'react';
import * as SocketConnection from '../utils/SocketConnection';
import * as SyncStatusChecker from '../utils/SyncStatusChecker';
import prayerIcon from '../assets/Prayer_icon.png';
import hitpointsIcon from '../assets/Hitpoints_icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import positionLogo from '../assets/position_icon.png';
import logoutLogo from '../assets/Logout.png';
import inventoryLogo from '../assets/Inventory_icon.png';
import equipmentLogo from '../assets/equipment_icon2.png';
import chatboxLogo from '../assets/chatbox_icon.png';
import widgetLogo from '../assets/widget_icon2.png';
const PolyGrid = (props) => {
	const { client, grid_size } = props;

	function getStatPercentage(current, max) {
		return Math.round((1 / (max / current)) * 100 * 100) / 100;
	}
	function displayClickable(displayName) {
		SocketConnection.sendRSClientToggleFocus(displayName);
	}
	if (client.loginScreenSrc) {
		return (
			<Fragment>
				<div className={`account-view-container ${grid_size || 'medium-view'}`}>
					<div className="hover-info-container">
						<div className="title-area">
							<p className="hover-only-title">
								<FontAwesomeIcon icon={faEye} />
							</p>
						</div>
					</div>
					<div className="account-view">
						<div
							key={client.index}
							className="login-screen-image-container"
							onClick={(e) => displayClickable(client.statsInfo.displayName)}
						>
							<img src={client.loginScreenSrc} />
						</div>
					</div>
				</div>
			</Fragment>
		);
	} else {
		return (
			<Fragment>
				<div
					className={`account-view-container ${grid_size || 'medium-view'}`}
					key={client.index}
				>
					{client.statsInfo && (
						<div className="hover-info-container">
							<div className="title-area">
								<p className="hover-only-title">
									<FontAwesomeIcon icon={faEye} />
								</p>
							</div>
						</div>
					)}

					<div className="account-view">
						{client.statsInfo &&
							SyncStatusChecker.isEnabled &&
							SyncStatusChecker.syncList[client.statsInfo.displayName] &&
							SyncStatusChecker.isAccountOutOfSync(
								client.statsInfo.displayName
							) && (
								<div
									onClick={(e) =>
										displayClickable(client.statsInfo.displayName)
									}
									className="out-of-sync-overlay"
								></div>
							)}
						<div
							className="flex grid-out-of-sync-image-container"
							onClick={(e) => displayClickable(client.statsInfo.displayName)}
						>
							{client.statsInfo &&
								SyncStatusChecker.syncList[client.statsInfo.displayName] &&
								SyncStatusChecker.getIsOutOfInventorySync(
									client.statsInfo.displayName
								) && (
									<img className="out-of-sync-image" src={inventoryLogo}></img>
								)}
							{client.statsInfo &&
								SyncStatusChecker.syncList[client.statsInfo.displayName] &&
								SyncStatusChecker.getIsOutOfEquipmentSync(
									client.statsInfo.displayName
								) && (
									<img className="out-of-sync-image" src={equipmentLogo}></img>
								)}
							{client.statsInfo &&
								SyncStatusChecker.syncList[client.statsInfo.displayName] &&
								SyncStatusChecker.getIsOutOfChatDialogueSync(
									client.statsInfo.displayName
								) && (
									<img className="out-of-sync-image" src={chatboxLogo}></img>
								)}
							{client.statsInfo &&
								SyncStatusChecker.syncList[client.statsInfo.displayName] &&
								!SyncStatusChecker.getIsOutOfLoginSync(
									client.statsInfo.displayName
								) &&
								SyncStatusChecker.getIsOutOfPositionSync(
									client.statsInfo.displayName
								) && (
									<img className="out-of-sync-image" src={positionLogo}></img>
								)}
							{client.statsInfo &&
								SyncStatusChecker.syncList[client.statsInfo.displayName] &&
								SyncStatusChecker.getIsOutOfLoginSync(
									client.statsInfo.displayName
								) && <img className="out-of-sync-image" src={logoutLogo}></img>}
							{client.statsInfo &&
								SyncStatusChecker.syncList[client.statsInfo.displayName] &&
								SyncStatusChecker.getIsOutOfWidgetSync(
									client.statsInfo.displayName
								) && <img className="out-of-sync-image" src={widgetLogo}></img>}
							{/* {client.statsInfo &&
								SyncStatusChecker.syncList[client.statsInfo.displayName] &&
								SyncStatusChecker.getIsIdle(
									client.statsInfo.displayName
								) && <img className="out-of-sync-image" src={widgetLogo}></img>} */}
						</div>
						<div
							className="stats-area"
							style={client.statsInfo ? null : { opacity: 0.02 }}
						>
							<div className="hitpoints-display">
								<div className="percentage-container">
									<div
										className="percentage-filler"
										style={{
											height: `${getStatPercentage(
												client.statsInfo ? client.statsInfo.hitpoints : 1,
												client.statsInfo ? client.statsInfo.maxHitpoints : 1
											)}%`,
										}}
									>
										<img src={hitpointsIcon}></img>
									</div>
								</div>
							</div>
							<div className="prayer-display">
								<div className="percentage-container">
									<div
										className="percentage-filler"
										style={{
											height: `${getStatPercentage(
												client.statsInfo ? client.statsInfo.prayerPoints : 1,
												client.statsInfo ? client.statsInfo.maxPrayerPoints : 1
											)}%`,
										}}
									>
										<img src={prayerIcon}></img>
									</div>
								</div>
							</div>
						</div>
						<div
							className="game-screen-image-container"
							onClick={(e) => displayClickable(client.statsInfo.displayName)}
						>
							{SocketConnection.getPolyMode() && (
								<img src={client.gameScreenSrc} />
							)}
							{client.chatboxSrc && (
								<div
									className="chatbox-image-container"
									onClick={(e) =>
										displayClickable(client.statsInfo.displayName)
									}
								>
									{SocketConnection.getPolyMode() && (
										<img src={client.chatboxSrc} />
									)}
								</div>
							)}
						</div>
						{client.inventorySrc && (
							<div
								className="inventory-image-container"
								onClick={(e) => displayClickable(client.statsInfo.displayName)}
							>
								{SocketConnection.getPolyMode() && (
									<img src={client.inventorySrc} />
								)}
							</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
};

export default PolyGrid;
