import * as SocketConnection from '../utils/SocketConnection';
import * as SyncStatusChecker from '../utils/SyncStatusChecker';

import React, { Fragment, useState, useRef } from 'react';
import ReactSlider from 'react-slider';
import '../css/slider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import Switch from 'react-switch';
import config from '../config.json';
import positionLogo from '../assets/position_icon.png';
import inventoryLogo from '../assets/Inventory_icon.png';
import disableMasterLogo from '../assets/sync-notifier-cursor.png';

import chatboxLogo from '../assets/chatbox_icon.png';
import widgetLogo from '../assets/widget_icon2.png';
import equipmentLogo from '../assets/equipment_icon2.png';
const ControlButtons = (props) => {
	const identifier = 'CONTROL_BUTTONS';
	const [hasFetchedAddOns, setHasFetchedAddOns] = useState(false);
	const [echoStatus, setEchoStatus] = useState(false);
	const [formationToggleStatus, setFormationToggleStatus] = useState(false);
	const [tickPerfectToggle, setTickPerfectToggle] = useState(false);
	const [lowCpuModeToggle, setLowCpuModeToggle] = useState(false);
	const [syncNotifierToggle, setSyncNotifierToggle] = useState(false);
	const [syncNotifierToggleInventory, setSyncNotifierToggleInventory] =
		useState(false);
	const [syncNotifierToggleDisableMaster, setSyncNotifierToggleDisableMaster] =
		useState(false);

	const [syncNotifierToggleEquipment, setSyncNotifierToggleEquipment] =
		useState(false);
	const [syncNotifierToggleWidget, setSyncNotifierToggleWidget] =
		useState(false);
	const [syncNotifierToggleChatbox, setSyncNotifierToggleChatbox] =
		useState(false);
	const [syncNotifierTogglePosition, setSyncNotifierTogglePosition] =
		useState(false);

	const [syncNotifierPositionRadius, setSyncNotifierPositionRadius] =
		useState(8);

	const [
		syncNotifierOutOfSyncTimeThreshold,
		setSyncNotifierOutOfSyncTimeThreshold,
	] = useState(100);

	const [worldType, setWorldType] = useState('F2P');

	const [autoEatModeToggle, setAutoEatModeToggle] = useState(false);
	const [lootFocusModeToggle, setLootFocusModeToggle] = useState(false);
	const [dynamicMasterToggle, setDynamicMasterToggle] = useState(false);
	const [dynamicNpcModeToggle, setDynamicNpcModeToggle] = useState(false);
	const [polyMode, setPolyMode] = useState(true);
	const [teleportPlayerSightModeToggle, setTeleportPlayerSightModeToggle] =
		useState(false);

	const [popFormations, setPopFormations] = useState(false);
	const [popDelayToggler, setPopDelayToggler] = useState(false);
	const [popTeleportToggler, setPopTeleportToggler] = useState(false);
	const [delaySetting, setDelaySetting] = useState(0);
	const [distanceSetting, setDistanceSetting] = useState(6);
	const [eatAtPercentage, setEatAtPercentage] = useState(70);
	const [comboEatAtPercentage, setComboEatAtPercentage] = useState(40);
	const [restoreAtPercentage, setRestoreAtPercentage] = useState(38);
	const [lootFocusValue, setLootFocusValue] = useState(10);
	const [ownsAutoEat, setOwnsAutoEat] = useState(false);
	const [ownsSyncNotifier, setOwnsSyncNotifier] = useState(false);
	const [loadedAddOns, setLoadedAddons] = useState(false);
	const stopBtn = useRef();
	const [toggleBtnAnim, setToggleBtnAnim] = useState(false);

	React.useEffect(() => {
		//render the control buttons, credit, etc

		if (!hasFetchedAddOns) {
			console.log('GETTING ADD ONS');
			getAddOns(function (auto_eat, sync_notifier) {
				setOwnsAutoEat(auto_eat);
				setHasFetchedAddOns(true);
				setOwnsSyncNotifier(true); //TODO set back to sync_notifier once freebie time over
				setLoadedAddons(true);
			});
		}

		setTimeout(() => {
			setToggleBtnAnim(!toggleBtnAnim);
		}, 1000);

		SocketConnection.addEventSubscriber(identifier, handleControlUpdates);
	}, [
		echoStatus,
		formationToggleStatus,
		setLoadedAddons,
		setOwnsAutoEat,
		toggleBtnAnim,
	]);

	async function getAddOns(callback) {
		const body = {
			email: SocketConnection.getEmail(),
		};
		const response = await fetch(config.REST_API_ENDPOINT + '/add_ons', {
			method: 'POST',
			body: JSON.stringify(body),
			headers: { 'Content-Type': 'application/json' },
		});
		const result = await response.json();

		return callback(result.AUTO_EAT, result.SYNC_NOTIFIER);
	}
	function handleControlUpdates(input) {
		const commands = input.split('::END');
		for (const data of commands) {
			if (data.includes('ROUTINE_STATUS')) {
				const status = data.split('ROUTINE_STATUS:')[1];

				if (status != null) {
					props.setCallbackEcho(status.includes('ECHO'));
					setEchoStatus(status.includes('ECHO'));
				}
			}
			if (data.includes('ECHO_DELAY')) {
				const status = data.split('ECHO_DELAY:')[1];
				setDelaySetting(parseInt(status));
			}

			if (data.includes('TICK_PERFECT_TOGGLE_MODE')) {
				const status = data.split('TICK_PERFECT_TOGGLE_MODE:')[1];

				if (status.includes('false')) {
					setTickPerfectToggle(false);
				} else if (status.includes('true')) {
					setTickPerfectToggle(true);
				}
			}
			if (data.includes('LOW_CPU_MODE')) {
				const status = data.split('LOW_CPU_MODE:')[1];
				if (status.includes('false')) {
					setLowCpuModeToggle(false);
				} else if (status.includes('true')) {
					setLowCpuModeToggle(true);
				}
			}
			if (data.includes('SYNC_NOTIFIER')) {
				if (data.includes('SYNC_NOTIFIER_INVENTORY')) {
					const status = data.split('SYNC_NOTIFIER_INVENTORY:')[1];
					if (status.includes('false')) {
						setSyncNotifierToggleInventory(false);
						SyncStatusChecker.setInventorySyncEnabled(false);
					} else if (status.includes('true')) {
						setSyncNotifierToggleInventory(true);
						SyncStatusChecker.setInventorySyncEnabled(true);
					}
				} else if (data.includes('SYNC_NOTIFIER_EQUIPMENT')) {
					const status = data.split('SYNC_NOTIFIER_EQUIPMENT:')[1];
					if (status.includes('false')) {
						setSyncNotifierToggleEquipment(false);
						SyncStatusChecker.setEquipmentSyncEnabled(false);
					} else if (status.includes('true')) {
						setSyncNotifierToggleEquipment(true);
						SyncStatusChecker.setEquipmentSyncEnabled(true);
					}
				} else if (data.includes('SYNC_NOTIFIER_WIDGET')) {
					const status = data.split('SYNC_NOTIFIER_WIDGET:')[1];
					if (status.includes('false')) {
						setSyncNotifierToggleWidget(false);
						SyncStatusChecker.setWidgetSyncEnabled(false);
					} else if (status.includes('true')) {
						setSyncNotifierToggleWidget(true);
						SyncStatusChecker.setWidgetSyncEnabled(true);
					}
				} else if (data.includes('SYNC_NOTIFIER_CHATBOX')) {
					const status = data.split('SYNC_NOTIFIER_CHATBOX:')[1];
					if (status.includes('false')) {
						setSyncNotifierToggleChatbox(false);
						SyncStatusChecker.setChatboxSyncEnabled(false);
					} else if (status.includes('true')) {
						setSyncNotifierToggleChatbox(true);
						SyncStatusChecker.setChatboxSyncEnabled(true);
					}
				} else if (data.includes('SYNC_NOTIFIER_INVENTORY')) {
					const status = data.split('SYNC_NOTIFIER_INVENTORY:')[1];
					if (status.includes('false')) {
						setSyncNotifierToggleInventory(false);
						SyncStatusChecker.setInventorySyncEnabled(false);
					} else if (status.includes('true')) {
						setSyncNotifierToggleInventory(true);
						SyncStatusChecker.setInventorySyncEnabled(true);
					}
				} else if (data.includes('SYNC_NOTIFIER_DISABLE_MASTER')) {
					const status = data.split('SYNC_NOTIFIER_DISABLE_MASTER:')[1];
					if (status.includes('false')) {
						setSyncNotifierToggleDisableMaster(false);
					} else if (status.includes('true')) {
						setSyncNotifierToggleDisableMaster(true);
					}
				} else if (data.includes('SYNC_NOTIFIER_POSITION')) {
					const status = data.split('SYNC_NOTIFIER_POSITION:')[1].split(':')[0];
					const radius = data.split('SYNC_NOTIFIER_POSITION:')[1].split(':')[1];

					if (status.includes('false')) {
						setSyncNotifierTogglePosition(false);
						SyncStatusChecker.setPositionSyncEnabled(false);
					} else if (status.includes('true')) {
						setSyncNotifierPositionRadius(radius);
						setSyncNotifierTogglePosition(true);
						SyncStatusChecker.setPositionSyncEnabled(true);
						SyncStatusChecker.setPositionRadius(radius);
					}
				} else if (data.includes('SYNC_NOTIFIER_THRESHOLD')) {
					const threshold = parseInt(data.split('SYNC_NOTIFIER_THRESHOLD:')[1]);
					console.log('SET THRESHOLD ' + threshold);
					setSyncNotifierOutOfSyncTimeThreshold(threshold);
					SyncStatusChecker.setSyncLossTimerThreshold(threshold);
				} else {
					const status = data.split('SYNC_NOTIFIER:')[1];
					if (status.includes('false')) {
						setSyncNotifierToggle(false);
						SyncStatusChecker.setEnabled(false);
					} else if (status.includes('true')) {
						setSyncNotifierToggle(true);
						SyncStatusChecker.setEnabled(true);
					}
				}
			}
			if (data.includes('DEFAULT_WORLD_TYPE')) {
				const type = data.split('DEFAULT_WORLD_TYPE:')[1];
				if (data.includes('F2P')) {
					setWorldType('F2P');
				} else {
					setWorldType('MEMBERS');
				}
			}
			if (data.includes('AUTO_EAT_MODE')) {
				const status = data.split('AUTO_EAT_MODE:')[1];
				if (status.includes('false')) {
					setAutoEatModeToggle(false);
				} else if (status.includes('true')) {
					setAutoEatModeToggle(true);
				}
			}
			if (data.includes('TELEPORT_PLAYER_SIGHT_MODE')) {
				const splitData = data.split('TELEPORT_PLAYER_SIGHT_MODE:')[1];
				const status = splitData.split('-')[0];
				const distance = splitData.split('-')[1];
				setDistanceSetting(distance);
				if (status.includes('false')) {
					setTeleportPlayerSightModeToggle(false);
				} else if (status.includes('true')) {
					setTeleportPlayerSightModeToggle(true);
				}
			}
			if (data.includes('LOOT_FOCUS_MODE')) {
				const status = data.split('LOOT_FOCUS_MODE:')[1];
				const value = status.split('_')[1];

				if (status.includes('false')) {
					setLootFocusModeToggle(false);
				} else if (status.includes('true')) {
					setLootFocusValue(value);
					setLootFocusModeToggle(true);
				}
			}
			if (data.includes('DYNAMIC_NPC_MODE')) {
				const status = data.split('DYNAMIC_NPC_MODE:')[1];

				if (status.includes('false')) {
					setDynamicNpcModeToggle(false);
				} else if (status.includes('true')) {
					setDynamicNpcModeToggle(true);
				}
			}
			if (data.includes('DYNAMIC_MASTER_MODE')) {
				const status = data.split('DYNAMIC_MASTER_MODE:')[1];
				if (status.includes('false')) {
					setDynamicMasterToggle(false);
				} else if (status.includes('true')) {
					setDynamicMasterToggle(true);
				}
			}
		}
	}
	function requestToggleFormation(type) {
		SocketConnection.sendFormationToggle(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleTickPerfect(status) {
		SocketConnection.sendToggleTickPerfect(status);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleLowCPUMode(type) {
		SocketConnection.sendToggleLowCPUMode(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}

	function requestWorldHop(type) {
		SocketConnection.sendRequestWorldHop(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}

	function requestToggleSyncNotifierInventory(type) {
		SocketConnection.sendToggleSyncNotifierInventory(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleSyncNotifierDisableMaster(type) {
		SocketConnection.sendToggleSyncNotifierDisableMaster(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}

	function requestToggleSyncNotifierEquipment(type) {
		SocketConnection.sendToggleSyncNotifierEquipment(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleSyncNotifierWidget(type) {
		SocketConnection.sendToggleSyncNotifierWidget(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleSyncNotifierPosition(type) {
		if (ownsSyncNotifier) {
			SocketConnection.sendToggleSyncNotifierPosition(
				type,
				syncNotifierPositionRadius
			);
			SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
		}
	}
	function requestToggleSyncNotifierChatbox(type) {
		SocketConnection.sendToggleSyncNotifierChatbox(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleSyncNotifier(type) {
		SocketConnection.sendToggleSyncNotifier(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}

	function requestToggleWorldType(type) {
		SocketConnection.sendToggleWorldType(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}

	function requestToggleAutoEatMode(type) {
		SocketConnection.sendToggleAutoEatMode(
			type,
			eatAtPercentage,
			comboEatAtPercentage,
			restoreAtPercentage
		);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleTeleportPlayerSightMode(type) {
		SocketConnection.sendToggleTeleportPlayerSightMode(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleLootFocusMode(type) {
		SocketConnection.sendToggleLootFocusMode(type, lootFocusValue);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleDynamicMasterMode(type) {
		SocketConnection.sendToggleDynamicMasterMode(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleDynamicNpcMode(type) {
		SocketConnection.sendToggleDynamicNpcMode(type);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestToggleFollowMaster(type) {
		SocketConnection.sendFollowMasterCommand(type);
	}

	function requestTerminateConnections() {
		SocketConnection.sendTerminateConnections();

		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}
	function requestEchoChange() {
		SocketConnection.sendEchoToggle(!echoStatus);

		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
	}

	function sliderChanged(value, index) {
		SocketConnection.sendDelayValue(value);
		SocketConnection.requestBasicData(); //get an immediate poll to ensure its set
		setDelaySetting(value);
	}
	function teleportDistanceSliderChanged(value, index) {
		SocketConnection.sendToggleTeleportPlayerSightMode(true, value);
		SocketConnection.requestBasicData(); //get an immediate poll to ensure its set
		setDistanceSetting(value);
	}
	function syncNotifierPositionRadiusSliderChanged(value, index) {
		SocketConnection.sendToggleSyncNotifierPosition(true, value);
		SocketConnection.requestBasicData(); //get an immediate poll to ensure its set
	}
	function syncNotifierOutOfSyncTimeThresholdSliderChanged(value, index) {
		SocketConnection.sendToggleSyncNotifierTimeThreshold(true, value);
		SocketConnection.requestBasicData(); //get an immediate poll to ensure its set
	}
	function lootFocusSliderChanged(value, index) {
		SocketConnection.sendToggleLootFocusMode(true, value);
		SocketConnection.requestBasicData(); //get an immediate poll to retrieve new status
		setLootFocusValue(value);
	}
	function eatAtPercentageSliderChanged(value, index) {
		SocketConnection.sendToggleAutoEatMode(
			true,
			value,
			comboEatAtPercentage,
			restoreAtPercentage
		);
		SocketConnection.requestBasicData(); //get an immediate poll to ensure its set
		setEatAtPercentage(value);
	}
	function comboEatAtPercentageSliderChanged(value, index) {
		SocketConnection.sendToggleAutoEatMode(
			true,
			eatAtPercentage,
			value,
			restoreAtPercentage
		);
		SocketConnection.requestBasicData(); //get an immediate poll to ensure its set
		setComboEatAtPercentage(value);
	}
	function restoreAtPercentageSliderChanged(value, index) {
		SocketConnection.sendToggleAutoEatMode(
			true,
			eatAtPercentage,
			comboEatAtPercentage,
			value
		);
		SocketConnection.requestBasicData(); //get an immediate poll to ensure its set
		setRestoreAtPercentage(value);
	}
	if (!loadedAddOns) return null;
	if (echoStatus)
		return (
			<div>
				<button
					className={
						!toggleBtnAnim
							? 'dashboard-start-button toggled dashboard-start-button__hover'
							: 'dashboard-start-button toggled'
					}
					type="button"
					id="stop-btn"
					onClick={requestEchoChange}
					ref={stopBtn}
				>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span>s</span>
					<span>t</span>
					<span>o</span>
					<span>p</span>
				</button>
				{lowCpuModeToggle && (
					<button
						className="dashboard-main-button toggle-off-button"
						style={{ backgroundColor: '#2c2e31' }}
						type="button"
						onClick={() => requestToggleLowCPUMode(false)}
					>
						Disable Low CPU Mode
					</button>
				)}
				{!lowCpuModeToggle && (
					<button
						className="dashboard-main-button"
						type="button"
						onClick={() => requestToggleLowCPUMode(true)}
					>
						Enable Low CPU Mode
					</button>
				)}
				{!polyMode && (
					<button
						className="dashboard-main-button"
						type="button"
						onClick={() => {
							setPolyMode(true);
							SocketConnection.setPolyMode(true);
						}}
					>
						Enable Image Display
					</button>
				)}
				{polyMode && (
					<button
						className="dashboard-main-button"
						type="button"
						onClick={() => {
							setPolyMode(false);
							SocketConnection.setPolyMode(false);
						}}
					>
						Disable Image Display
					</button>
				)}
				{syncNotifierToggle && (
					<Fragment>
						<button
							className={
								ownsSyncNotifier
									? 'dashboard-main-button toggle-off-button add-on-color'
									: 'dashboard-main-button add-on-disabled'
							}
							style={{ backgroundColor: '#2c2e31' }}
							type="button"
							onClick={() =>
								ownsSyncNotifier
									? requestToggleSyncNotifier(false)
									: window.open(
											'https://osrsmultibox.com/product/multibox-sync-notifier-add-on/',
											'_blank'
									  )
							}
						>
							{ownsSyncNotifier
								? 'Disable Sync Notifier'
								: 'Enable Sync Notifier'}
						</button>
						<div
							className={
								ownsSyncNotifier
									? 'sync-notifier-section'
									: 'sync-notifier-section disabled-sync-notifier'
							}
						>
							<div className="flex checkbox-container">
								<img src={disableMasterLogo}></img>
								<label
									className="checkbox"
									onClick={() =>
										requestToggleSyncNotifierDisableMaster(
											!syncNotifierToggleDisableMaster
										)
									}
								>
									<input
										type="checkbox"
										className="custom-checkbox"
										checked={syncNotifierToggleDisableMaster}
										disabled={!ownsSyncNotifier}
									></input>
									<label
										onClick={() =>
											requestToggleSyncNotifierDisableMaster(
												!syncNotifierToggleDisableMaster
											)
										}
									>
										Disable Input if OOS
									</label>
								</label>
							</div>
							<div className="delay-slider-container">
								<h5 className="slider-title">
									Trigger OOS Notify if out of sync for:{' '}
								</h5>

								<ReactSlider
									max={10000}
									onAfterChange={
										syncNotifierOutOfSyncTimeThresholdSliderChanged
									}
									value={parseInt(syncNotifierOutOfSyncTimeThreshold) || 0}
									className="horizontal-slider"
									thumbClassName="example-thumb"
									trackClassName="example-track position-radius-track"
									renderThumb={(props, state) => (
										<div {...props}>{state.valueNow}ms</div>
									)}
									disabled={!ownsSyncNotifier}
								/>
							</div>
							<br></br>
							<br></br>
							<div className="flex checkbox-container">
								<img src={inventoryLogo}></img>
								<label
									className="checkbox"
									onClick={() =>
										requestToggleSyncNotifierInventory(
											!syncNotifierToggleInventory
										)
									}
								>
									<input
										type="checkbox"
										className="custom-checkbox"
										checked={syncNotifierToggleInventory}
										disabled={!ownsSyncNotifier}
									></input>
									<label
										onClick={() =>
											requestToggleSyncNotifierInventory(
												!syncNotifierToggleInventory
											)
										}
									>
										Notify Inventory
									</label>
								</label>
							</div>
							<div className="flex checkbox-container">
								<img src={equipmentLogo}></img>
								<label
									className="checkbox"
									onClick={() =>
										requestToggleSyncNotifierEquipment(
											!syncNotifierToggleEquipment
										)
									}
								>
									<input
										type="checkbox"
										className="custom-checkbox"
										checked={syncNotifierToggleEquipment}
										disabled={!ownsSyncNotifier}
									></input>
									<label
										onClick={() =>
											requestToggleSyncNotifierEquipment(
												!syncNotifierToggleEquipment
											)
										}
									>
										Notify Equipment
									</label>
								</label>
							</div>
							<div
								className="flex checkbox-container"
								onClick={() =>
									requestToggleSyncNotifierWidget(!syncNotifierToggleWidget)
								}
							>
								<img src={widgetLogo}></img>
								<label className="checkbox">
									<input
										type="checkbox"
										className="custom-checkbox"
										checked={syncNotifierToggleWidget}
										disabled={!ownsSyncNotifier}
									></input>
									<label>Notify Interfaces</label>
								</label>
							</div>

							<div className="flex checkbox-container">
								<img src={chatboxLogo}></img>
								<label
									className="checkbox"
									onClick={() =>
										requestToggleSyncNotifierChatbox(!syncNotifierToggleChatbox)
									}
								>
									<input
										type="checkbox"
										className="custom-checkbox"
										checked={syncNotifierToggleChatbox}
										disabled={!ownsSyncNotifier}
									></input>
									<label
										onClick={() =>
											requestToggleSyncNotifierChatbox(
												!syncNotifierToggleChatbox
											)
										}
									>
										Notify NPC Chatbox
									</label>
								</label>
							</div>
							<div className="flex checkbox-container">
								<img src={positionLogo}></img>
								<label
									className="checkbox"
									onClick={() =>
										requestToggleSyncNotifierPosition(
											!syncNotifierTogglePosition
										)
									}
								>
									<input
										type="checkbox"
										className="custom-checkbox"
										checked={syncNotifierTogglePosition}
										disabled={!ownsSyncNotifier}
									></input>
									<label
										onClick={() =>
											requestToggleSyncNotifierPosition(
												!syncNotifierTogglePosition
											)
										}
									>
										Notify Position
									</label>
								</label>
							</div>
							<br></br>

							<div className="delay-slider-container">
								<h5 className="slider-title">Position tile radius</h5>

								<ReactSlider
									max={15}
									onAfterChange={syncNotifierPositionRadiusSliderChanged}
									value={parseInt(syncNotifierPositionRadius) || 0}
									className="horizontal-slider"
									thumbClassName="example-thumb"
									trackClassName="example-track position-radius-track"
									renderThumb={(props, state) => (
										<div {...props}>{state.valueNow}</div>
									)}
									disabled={!ownsSyncNotifier}
								/>
							</div>
						</div>
					</Fragment>
				)}
				{!syncNotifierToggle && (
					<button
						className="dashboard-main-button add-on-color"
						type="button"
						onClick={() => requestToggleSyncNotifier(true)}
					>
						Enable Sync Notifier
					</button>
				)}
				{/* {tickPerfectToggle && (
					<button
						className="dashboard-main-button toggle-off-button"
						style={{ backgroundColor: '#2c2e31' }}
						type="button"
						onClick={() => requestToggleTickPerfect(false)}
					>
						Disable Tick Perfect
					</button>
				)}
				{!tickPerfectToggle && (
					<button
						className="dashboard-main-button feature-disabled"
						type="button"
						onClick={() => requestToggleTickPerfect(true)}
					>
						Enable Tick Perfect
					</button>
				)} */}
				{dynamicMasterToggle && (
					<button
						className="dashboard-main-button toggle-off-button"
						style={{ backgroundColor: '#2c2e31' }}
						type="button"
						onClick={() => requestToggleDynamicMasterMode(false)}
					>
						Disable Fluid Master
					</button>
				)}
				{!dynamicMasterToggle && (
					<button
						className="dashboard-main-button"
						type="button"
						onClick={() => requestToggleDynamicMasterMode(true)}
					>
						Enable Fluid Master
					</button>
				)}
				{/* {!popDelayToggler && (
					<button
						className="dashboard-main-button feature-disabled"
						type="button"
						onClick={() => setPopDelayToggler(true)}
					>
						Set Delay
					</button>
				)}
				{popDelayToggler && (
					<Fragment>
						<button
							className="dashboard-main-button"
							type="button"
							style={{ backgroundColor: '#2c2e31' }}
							onClick={() => setPopDelayToggler(false)}
						>
							Set Delay
						</button>

						<div className="delay-slider-container">
							<ReactSlider
								max={5000}
								onAfterChange={sliderChanged}
								defaultValue={delaySetting}
								className="horizontal-slider"
								thumbClassName="example-thumb"
								trackClassName="example-track"
								renderThumb={(props, state) => (
									<div {...props}>{state.valueNow}ms</div>
								)}
							/>
						</div>
					</Fragment>
				)} */}
				{lootFocusModeToggle && (
					<Fragment>
						<button
							className="dashboard-main-button toggle-off-button"
							style={{ backgroundColor: '#2c2e31' }}
							type="button"
							onClick={() => requestToggleLootFocusMode(false)}
						>
							Disable Loot Focus
						</button>
						<div className="delay-slider-container">
							<h4 className="slider-title"> Focus on loot value (minimum) </h4>
							<ReactSlider
								max={500}
								onAfterChange={lootFocusSliderChanged}
								defaultValue={lootFocusValue}
								className="horizontal-slider"
								thumbClassName="example-thumb"
								trackClassName="example-track drop-value-track"
								renderThumb={(props, state) => (
									<div {...props}>{state.valueNow}K</div>
								)}
							/>
						</div>
					</Fragment>
				)}
				{!lootFocusModeToggle && (
					<button
						className="dashboard-main-button"
						type="button"
						onClick={() => requestToggleLootFocusMode(true)}
					>
						Enable Loot Focus
					</button>
				)}
				<button
					className="dashboard-main-button"
					type="button"
					onClick={() => requestWorldHop(-1)}
				>
					Switch Own Worlds
				</button>
				{!popFormations && (
					<button
						className="dashboard-main-button formations-toggler show-formations-btn"
						type="button"
						onClick={() => setPopFormations(true)}
					>
						Show Formations
					</button>
				)}
				{popFormations && (
					<Fragment>
						<button
							className="dashboard-main-button formations-toggler"
							type="button"
							style={{ backgroundColor: '#2c2e31' }}
							onClick={() => setPopFormations(false)}
						>
							Hide Formations
						</button>
						<button
							className="dashboard-main-button toggle-formation-button"
							type="button"
							onClick={() => requestToggleFormation('NONE')}
						>
							DOT Formation
						</button>
						<button
							className=" dashboard-main-button toggle-formation-button"
							type="button"
							onClick={() => requestToggleFormation('5X5_GRID')}
						>
							5x5 Grid Formation
						</button>
						<button
							className=" dashboard-main-button toggle-formation-button"
							type="button"
							onClick={() => requestToggleFormation('4X4_GRID')}
						>
							4x4 Grid Formation
						</button>
						<button
							className=" dashboard-main-button toggle-formation-button"
							type="button"
							onClick={() => requestToggleFormation('3X3_GRID')}
						>
							3x3 Grid Formation
						</button>
						<button
							className=" dashboard-main-button toggle-formation-button"
							type="button"
							onClick={() => requestToggleFormation('2X2_GRID')}
						>
							2x2 Grid Formation
						</button>
						<button
							className=" dashboard-main-button toggle-formation-button"
							type="button"
							onClick={() => requestToggleFormation('ANTI_BARRAGE')}
						>
							ANTI-BARRAGE Formation
						</button>
						<button
							className=" dashboard-main-button toggle-formation-button"
							type="button"
							onClick={() => requestToggleFormation('LINE_X')}
						>
							Line (x-axis)
						</button>
						<button
							className=" dashboard-main-button toggle-formation-button"
							type="button"
							onClick={() => requestToggleFormation('LINE_Y')}
						>
							Line (y-axis)
						</button>
					</Fragment>
				)}
				{autoEatModeToggle && ownsAutoEat && (
					<Fragment>
						<button
							className="dashboard-main-button toggle-off-button"
							style={{ backgroundColor: '#2c2e31' }}
							type="button"
							onClick={() => requestToggleAutoEatMode(false)}
						>
							Disable Auto Eat
						</button>
						<div
							className={
								ownsAutoEat
									? 'delay-slider-container'
									: 'disabled-no-auto-eat delay-slider-container'
							}
						>
							<h4 className="slider-title">Eat HP</h4>
							<ReactSlider
								max={100}
								onAfterChange={eatAtPercentageSliderChanged}
								defaultValue={eatAtPercentage}
								className="horizontal-slider"
								thumbClassName="example-thumb"
								trackClassName="example-track eat-track"
								renderThumb={(props, state) => (
									<div {...props}>{state.valueNow}%</div>
								)}
								disabled={!ownsAutoEat}
							/>
						</div>
						<div
							className={
								ownsAutoEat
									? 'delay-slider-container'
									: 'disabled-no-auto-eat delay-slider-container'
							}
						>
							<h4 className="slider-title">Combo Eat HP</h4>
							<ReactSlider
								max={100}
								onAfterChange={comboEatAtPercentageSliderChanged}
								defaultValue={comboEatAtPercentage}
								className="horizontal-slider"
								thumbClassName="example-thumb"
								trackClassName="example-track combo-eat-track"
								disabled={!ownsAutoEat}
								renderThumb={(props, state) => (
									<div {...props}>{state.valueNow}%</div>
								)}
							/>
						</div>
						<div
							className={
								ownsAutoEat
									? 'delay-slider-container'
									: 'disabled-no-auto-eat delay-slider-container'
							}
						>
							<h4 className="slider-title">Restore Prayer </h4>
							<ReactSlider
								max={100}
								onAfterChange={restoreAtPercentageSliderChanged}
								defaultValue={restoreAtPercentage}
								className="horizontal-slider"
								thumbClassName="example-thumb"
								trackClassName="example-track prayer-track"
								disabled={!ownsAutoEat}
								renderThumb={(props, state) => (
									<div {...props}>{state.valueNow}%</div>
								)}
							/>
						</div>
					</Fragment>
				)}
				{!autoEatModeToggle && ownsAutoEat && (
					<button
						className="dashboard-main-button add-on-color"
						type="button"
						onClick={() => requestToggleAutoEatMode(true)}
					>
						Enable Auto Eat
					</button>
				)}
				{!ownsAutoEat && (
					<button
						className="dashboard-main-button add-on-disabled"
						type="button"
						onClick={() =>
							window.open(
								' https://osrsmultibox.com/product/multibox-auto-eat-add-on/',
								'_blank'
							)
						}
					>
						Enable Auto Eat
					</button>
				)}
				{!ownsAutoEat && (
					<Fragment>
						<div
							className={
								ownsAutoEat
									? 'delay-slider-container'
									: 'disabled-no-auto-eat delay-slider-container'
							}
						>
							<h4 className="slider-title">Eat HP</h4>
							<ReactSlider
								max={100}
								onAfterChange={eatAtPercentageSliderChanged}
								defaultValue={eatAtPercentage}
								className="horizontal-slider"
								thumbClassName="example-thumb"
								trackClassName="example-track eat-track"
								renderThumb={(props, state) => (
									<div {...props}>{state.valueNow}%</div>
								)}
								disabled={!ownsAutoEat}
							/>
						</div>
						<div
							className={
								ownsAutoEat
									? 'delay-slider-container'
									: 'disabled-no-auto-eat delay-slider-container'
							}
						>
							<h4 className="slider-title">Combo Eat HP</h4>
							<ReactSlider
								max={100}
								onAfterChange={comboEatAtPercentageSliderChanged}
								defaultValue={comboEatAtPercentage}
								className="horizontal-slider"
								thumbClassName="example-thumb"
								trackClassName="example-track combo-eat-track"
								disabled={!ownsAutoEat}
								renderThumb={(props, state) => (
									<div {...props}>{state.valueNow}%</div>
								)}
							/>
						</div>
						<div
							className={
								ownsAutoEat
									? 'delay-slider-container'
									: 'disabled-no-auto-eat delay-slider-container'
							}
						>
							<h4 className="slider-title">Restore Prayer </h4>
							<ReactSlider
								max={100}
								onAfterChange={restoreAtPercentageSliderChanged}
								defaultValue={restoreAtPercentage}
								className="horizontal-slider"
								thumbClassName="example-thumb"
								trackClassName="example-track prayer-track"
								disabled={!ownsAutoEat}
								renderThumb={(props, state) => (
									<div {...props}>{state.valueNow}%</div>
								)}
							/>
						</div>
					</Fragment>
				)}
				{worldType == 'F2P' && (
					<button
						className="dashboard-main-button f2p-toggled-button"
						style={{ backgroundColor: '#2c2e31' }}
						type="button"
						onClick={() => requestToggleWorldType('MEMBERS')}
					>
						Default World: F2P
					</button>
				)}
				{worldType == 'MEMBERS' && (
					<button
						className="dashboard-main-button members-toggled-button"
						type="button"
						onClick={() => requestToggleWorldType('F2P')}
					>
						Default World: Members
					</button>
				)}
				<button
					className="dashboard-main-button terminate_button"
					type="button"
					onClick={requestTerminateConnections}
				>
					Terminate Connections
				</button>{' '}
			</div>
		);

	return (
		<div>
			<button
				className=" dashboard-start-button not-toggled"
				type="button"
				onClick={requestEchoChange}
			>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span>s</span>
				<span>t</span>
				<span>a</span>
				<span>r</span>
				<span>t</span>
			</button>
			{worldType == 'F2P' && (
				<button
					className="dashboard-main-button f2p-toggled-button"
					style={{ backgroundColor: '#2c2e31' }}
					type="button"
					onClick={() => requestToggleWorldType('MEMBERS')}
				>
					Default World: F2P
				</button>
			)}
			{worldType == 'MEMBERS' && (
				<button
					className="dashboard-main-button members-toggled-button"
					type="button"
					onClick={() => requestToggleWorldType('F2P')}
				>
					Default World: Members
				</button>
			)}
			<button
				className="dashboard-main-button terminate_button"
				type="button"
				onClick={requestTerminateConnections}
			>
				Terminate Connections
			</button>{' '}
		</div>
	);
};

export default ControlButtons;
