import React from 'react';

const ErrorSplash = (props) => {
	return (
		<div className="error-splash">
			<h2>{props.message} </h2>
		</div>
	);
};
export default ErrorSplash;
