import socketIOClient from 'socket.io-client';
import config from '../config.json';
let socket = null;
let token = null;
let connectionCallback = null;
let email = null;
let password = null;
let eventSubscribers = {};
let userServerAddress = null;
let enablePolyMode = true;

// {
// 	"REST_API_ENDPOINT": "https://api.osbox-dashboard.live",
// 	"SOCKETIO_ENDPOINT": "https://osbox-websocket-proxy.herokuapp.com",
// 	"POLY_SERVER_ENDPOINT": "https://osbox-poly-server-beta.herokuapp.com"
// }
export const setPolyMode = (value) => {
	enablePolyMode = value;
};
export const getPolyMode = () => {
	return enablePolyMode;
};
export const getSocket = () => {
	return socket;
};
export const setPassword = (_password) => {
	localStorage.setItem('password', _password);
	password = _password;
};
export const clearPassword = () => {
	localStorage.removeItem('password');
	password = null;
};
export const getPassword = () => {
	return password;
};
export const getUserServerAddress = () => {
	return userServerAddress;
};
export const clearEmail = () => {
	localStorage.removeItem('email');
	email = null;
};
export const setEmail = (_email) => {
	localStorage.setItem('email', _email);
	email = _email;
};
export const getEmail = () => {
	return email;
};

export const addEventSubscriber = (id, sub) => {
	eventSubscribers[id] = sub;
};
export const removeEventSubscriber = (id) => {
	delete eventSubscribers[id];
};
export const getServerAddress = async (connectionCallback) => {
	try {
		const body = {
			email,
		};
		const response = await fetch(
			config.REST_API_ENDPOINT + '/OSBoxServerAddress',
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: { 'Content-Type': 'application/json' },
			}
		);

		let result = await response.text();
		//temp US server address for everyone
		if (result != '127.0.0.1') {
			if (result == '52.62.216.107') {
				// result = '52.62.216.107';
				console.log('connecting australia');
			} else if (result == '122.58.123.195') {
				console.log('remapping gab local');
				result = '127.0.0.1';
			} else {
				result = '44.209.176.109';
				//result = '122.58.123.195' //temp gab local
			}
		}

		return result;
	} catch (e) {
		console.log(e);
		return connectionCallback({ restServerFail: true });
	}
};

export const createAuthorizedConnection = async (callback) => {
	if (socket !== null) return; //prevent recurisive connections via loadingchecker
	connectionCallback = callback;

	if (!email || email == 'null') {
		console.log('No email found..');
		return connectionCallback({ redirectLogin: true });
	}
	const serverAddress = await getServerAddress(connectionCallback);

	if (!serverAddress) return;
	userServerAddress = serverAddress;
	socket = socketIOClient(config.SOCKETIO_ENDPOINT);
	sendConnect(serverAddress);

	//connect/authenticate, show loading, and then redirect to real dashboard
	socket.on('connect', function () {
		console.log('connected to socket.io proxy');
	});
	socket.on('disconnect', function () {
		console.log('disconnected from socket.io proxy');
	});
	socket.on('tcp_connected', function (data) {
		console.log('TCP CONNECTED');
		sendTCPLoginCommand(email, password);
	});
	socket.on('event', function (data) {
		if (data.includes('AUTHENTICATION_FAILED')) {
			connectionCallback({ isValidToken: false });
		}
		if (data.includes('AUTHENTICATED')) {
			token = data.split('AUTHENTICATED:::')[1].split('__END')[0];

			notifyAuthenticatedConnection();
			console.log('Attempting to add dashboard as controller..');
			sendCommand('ADD_CONTROLLER');
			return;
		}
		parseTCPEvent(data);
	});
	socket.on('tcp_error', function (err) {
		console.log(err);
		connectionCallback({ tcpFail: true });
	});
	socket.io.on('connect_error', function (err) {
		// handle server error here
		connectionCallback({ proxyFail: true });
	});
};
function pollPolyData() {
	setTimeout(() => {
		sendCommand('REQUEST_CLIENT_POLY_DATA');
		pollPolyData();
	}, 250);
}
function pollPolyScreenshotData() {
	setTimeout(() => {
		if (enablePolyMode) {
			sendCommand('REQUEST_CLIENT_SCREENSHOT_POLY_DATA');
		}
		pollPolyScreenshotData();
	}, 1000); //750 for release
}

function notifyControllerAdded() {
	pollPolyData();
	pollPolyScreenshotData();
	connectionCallback({ isRegisteredController: true });
}
function notifyAuthenticatedConnection() {
	console.log('notify authenticated connection!');
	connectionCallback({ isValidToken: true });
}

function parseTCPEvent(data) {
	if (data.includes('CONTROLLER_ADDED')) {
		notifyControllerAdded();
	}

	for (const subscriber of Object.values(eventSubscribers)) {
		subscriber(data); //notify every fragment subscribed to tcp events
	}
}
export const sendConnect = (serverAddress) => {
	socket.emit('connect_tcp', { serverAddress });
};
export const sendCommand = (command) => {
	if (socket == null) return;
	socket.emit('data', command);
};
export const sendTCPLoginCommand = (_email, _password) => {
	socket.emit(
		'data',
		`LOGIN_REQUEST:EMAIL=${email}:::PASSWORD=${password}:::END`
	);
};

export const sendTerminateConnections = () => {
	sendCommand('KILL_ACCOUNTS');
};

export const sendDelayValue = (value) => {
	sendCommand('SET_ECHO_DELAY:' + value);
};

export const sendFormationToggle = (state) => {
	sendCommand('TOGGLE_FORMATION:' + state);
};

export const sendToggleWorldType = (type) => {
	if (type == 'MEMBERS') {
		sendCommand('TOGGLE_MEMBERS_WORLDS');
	} else {
		sendCommand('TOGGLE_F2P_WORLDS');
	}
};
export const sendToggleSyncNotifierInventory = (state) => {
	if (state) {
		sendCommand('ENABLE_SYNC_NOTIFIER_INVENTORY');
	} else {
		sendCommand('DISABLE_SYNC_NOTIFIER_INVENTORY');
	}
};
export const sendToggleSyncNotifierEquipment = (state) => {
	if (state) {
		sendCommand('ENABLE_SYNC_NOTIFIER_EQUIPMENT');
	} else {
		sendCommand('DISABLE_SYNC_NOTIFIER_EQUIPMENT');
	}
};

export const sendToggleSyncNotifierWidget = (state) => {
	if (state) {
		sendCommand('ENABLE_SYNC_NOTIFIER_WIDGET');
	} else {
		sendCommand('DISABLE_SYNC_NOTIFIER_WIDGET');
	}
};

export const sendToggleSyncNotifierDisableMaster = (state) => {
	if (state) {
		sendCommand('ENABLE_SYNC_NOTIFIER_DISABLE_MASTER');
	} else {
		sendCommand('DISABLE_SYNC_NOTIFIER_DISABLE_MASTER');
	}
};

export const sendToggleSyncNotifierChatbox = (state) => {
	if (state) {
		sendCommand('ENABLE_SYNC_NOTIFIER_CHATBOX');
	} else {
		sendCommand('DISABLE_SYNC_NOTIFIER_CHATBOX');
	}
};
export const sendToggleSyncNotifierPosition = (state, value) => {
	if (state) {
		sendCommand('ENABLE_SYNC_NOTIFIER_POSITION:' + value);
	} else {
		sendCommand('DISABLE_SYNC_NOTIFIER_POSITION');
	}
};
export const sendToggleSyncNotifierTimeThreshold = (state, value) => {
	sendCommand('SET_SYNC_NOTIFIER_THRESHOLD:' + value);
};

export const sendToggleSyncNotifier = (state) => {
	if (state) {
		sendCommand('ENABLE_SYNC_NOTIFIER');
	} else {
		sendCommand('DISABLE_SYNC_NOTIFIER');
	}
};
export const sendRequestWorldHop = (state) => {
	sendCommand('REQUEST_WORLD_HOP:' + state);
};
export const sendSyncNotifierStatus = (state) => {
	sendCommand('SET_SYNC_NOTIFIER_OUT_OF_SYNC:' + state);
};
export const sendToggleLowCPUMode = (state) => {
	if (state) {
		sendCommand('ENABLE_LOW_CPU_MODE');
	} else {
		sendCommand('DISABLE_LOW_CPU_MODE');
	}
};
export const sendToggleAutoEatMode = (
	state,
	eatAtPercentage,
	comboEatAtPercentage,
	restoreAtPercentage
) => {
	if (state) {
		sendCommand(
			'ENABLE_AUTO_EAT:' +
				eatAtPercentage +
				':' +
				comboEatAtPercentage +
				':' +
				restoreAtPercentage +
				':'
		);
	} else {
		sendCommand('DISABLE_AUTO_EAT');
	}
};
export const sendToggleTeleportPlayerSightMode = (state, distance) => {
	if (!distance) distance = 6;
	if (state) {
		sendCommand('ENABLE_TELEPORT_PLAYER_SIGHT:' + distance);
	} else {
		sendCommand('DISABLE_TELEPORT_PLAYER_SIGHT');
	}
};
export const sendToggleLootFocusMode = (state, value) => {
	if (state) {
		sendCommand('ENABLE_LOOT_FOCUS:' + value);
	} else {
		sendCommand('DISABLE_LOOT_FOCUS');
	}
};
export const sendToggleDynamicMasterMode = (state) => {
	if (state) {
		sendCommand('ENABLE_DYNAMIC_MASTER');
	} else {
		sendCommand('DISABLE_DYNAMIC_MASTER');
	}
};
export const sendToggleDynamicNpcMode = (state) => {
	if (state) {
		sendCommand('ENABLE_SIMILAR_NPC_MODE');
	} else {
		sendCommand('DISABLE_SIMILAR_NPC_MODE');
	}
};
export const sendFollowMasterCommand = () => {
	sendCommand('FOLLOW_MASTER');
};
export const sendToggleTickPerfect = (status) => {
	if (status) {
		sendCommand('ENABLE_TICK_PERFECT_TOGGLE');
	} else {
		sendCommand('DISABLE_TICK_PERFECT_TOGGLE');
	}
};

export const sendEchoToggle = (state) => {
	if (state) {
		sendCommand('START_ECHO');
	} else {
		sendCommand('STOP_ROUTINE');
	}
};
export const sendRSClientToggleFocus = (displayName) => {
	//TODO gab add back
	sendCommand(`TOGGLE_CLIENT_POPUP_${displayName}`);
	// sendCommand(`SET_MASTER_INDEX_${client_index}`);
};

export const sendPolyEmitterToggle = (toggle) => {
	if (toggle) {
		sendCommand('ENABLE_POLY_SENDING');
	} else {
		sendCommand('DISABLE_POLY_SENDING');
	}
};
export const requestBasicData = () => {
	sendCommand('ROUTINE_STATUS');
	// sendCommand('GET_CREDIT');
	sendCommand('GET_ACTIVE_CONNECTIONS');
	sendCommand('GET_FORMATION_STATUS');
	sendCommand('GET_LOW_CPU_TOGGLE_MODE');
	sendCommand('GET_ECHO_DELAY');
	sendCommand('GET_AUTO_EAT_MODE');
	sendCommand('GET_TELEPORT_PLAYER_SIGHT_MODE');
	sendCommand('GET_DYNAMIC_MASTER_MODE');
	sendCommand('GET_LOOT_FOCUS_MODE');
	sendCommand('GET_SIMILAR_NPC_MODE');
	sendCommand('GET_TICK_PERFECT_TOGGLE_STATUS');
};
export const requestPolyData = () => {
	sendPolyEmitterToggle(true);

	sendCommand('CLIENT_GAME_STATE');
};
