import React from 'react';
import loading from '../assets/loading.svg';
import osboxLogo from '../assets/osbox.png';
const Loading = () => (
	<div className="dark-theme">
		<div className="spinner">
			<img src={loading} alt="Loading" />
			<img src={osboxLogo} className="spinner-logo" alt="osboxlogo" />
		</div>
	</div>
);

export default Loading;
