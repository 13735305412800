export const syncList = {};
export let isEnabled = false;
export let inventorySyncEnabled = false;
export let equipmentSyncEnabled = true;
export let widgetSyncEnabled = false;
export let chatboxSyncEnabled = false;
export let positionSyncEnabled = false;
export let loginSyncEnabled = true; //enable by default when sync notifier is on
export let positionRadius = 500;
export let syncLossTimerThreshold = 150; //num ms of out of state to trigger sync loss
export let echoStatus = false;

export let idleNotifierEnabled = false;
export let idleNotifierThreshold = 5000; //time in seconds to notify after being idle for

// const syncPositionLossTimerThreshold = 1500;
export function setEchoStatus(value) {
	echoStatus = value;
}
export function setEnabled(value) {
	isEnabled = value;
}
export function setIdleNotifierEnabled(value) {
	idleNotifierEnabled = value;
}
export function setSyncLossTimerThreshold(value) {
	syncLossTimerThreshold = value;
}
export function setLoginSyncEnabled(value) {
	loginSyncEnabled = value;
}
export function setInventorySyncEnabled(value) {
	inventorySyncEnabled = value;
}
export function setEquipmentSyncEnabled(value) {
	equipmentSyncEnabled = value;
}
export function setWidgetSyncEnabled(value) {
	widgetSyncEnabled = value;
}
export function setChatboxSyncEnabled(value) {
	chatboxSyncEnabled = value;
}
export function setPositionSyncEnabled(value) {
	positionSyncEnabled = value;
}
export function setPositionRadius(value) {
	positionRadius = value;
}

const SYNC_TYPES = {
	POSITION: 0,
	INVENTORY: 1,
	INTERFACE: 2,
	INTERACTING_NPC: 3,
	CHAT_DIALGOUE: 4,
	EQUIPMENT: 5,
	LOGIN: 6,
	IDLE: 7,
};

export function getIsIdle(displayName) {
	if (!idleNotifierEnabled) return false;
	if (displayName) {
		if (syncList[displayName].isOutOfIdleSync) return true;
	} else {
		for (const key in syncList) {
			if (syncList[key].isOutOfIdleSync) return true;
		}
	}
	return false;
}

export function getIsOutOfLoginSync(displayName) {
	if (!loginSyncEnabled) return false;
	if (displayName) {
		if (syncList[displayName].isOutOfLoginSync) return true;
	} else {
		for (const key in syncList) {
			if (syncList[key].isOutOfLoginSync) return true;
		}
	}
	return false;
}

export function getIsOutOfPositionSync(displayName) {
	if (!positionSyncEnabled) return false;
	if (displayName) {
		if (syncList[displayName].isOutOfPositionSync) return true;
	} else {
		for (const key in syncList) {
			if (syncList[key].isOutOfPositionSync) return true;
		}
	}
	return false;
}
export function getIsOutOfInventorySync(displayName) {
	if (!inventorySyncEnabled) return false;
	if (displayName) {
		if (syncList[displayName].isOutOfInventorySync) return true;
	} else {
		for (const key in syncList) {
			if (syncList[key].isOutOfInventorySync) {
				console.log(key + ':' + syncList[key].isOutOfInventorySync);
			}
			if (syncList[key].isOutOfInventorySync) return true;
		}
	}
	return false;
}
export function getIsOutOfEquipmentSync(displayName) {
	if (!equipmentSyncEnabled) return false;
	if (displayName) {
		if (syncList[displayName].isOutOfEquipmentSync) return true;
	} else {
		for (const key in syncList) {
			if (syncList[key].isOutOfEquipmentSync) return true;
		}
	}
	return false;
}
export function getIsOutOfWidgetSync(displayName) {
	if (!widgetSyncEnabled) return false;
	if (displayName) {
		if (syncList[displayName].isOutOfWidgetSync) return true;
	} else {
		for (const key in syncList) {
			if (syncList[key].isOutOfWidgetSync) return true;
		}
	}
	return false;
}
export function getIsOutOfChatDialogueSync(displayName) {
	if (!chatboxSyncEnabled) return false;
	if (displayName) {
		if (syncList[displayName].isOutOfChatDialogueSync) return true;
	} else {
		for (const key in syncList) {
			if (syncList[key].isOutOfChatDialogueSync) return true;
		}
	}
	return false;
}
export function getIsOutOfSync() {
	for (const key in syncList) {
		if (isAccountOutOfSync(key)) {
			return true;
		}
	}
	return false;
}

export function isAccountOutOfSync(displayName) {
	if (echoStatus === false) return false;
	return (
		(syncList[displayName].isOutOfPositionSync && positionSyncEnabled) ||
		(syncList[displayName].isOutOfWidgetSync && widgetSyncEnabled) ||
		(syncList[displayName].isOutOfInventorySync && inventorySyncEnabled) ||
		(syncList[displayName].isOutOfChatDialogueSync && chatboxSyncEnabled) ||
		(syncList[displayName].isOutOfEquipmentSync && equipmentSyncEnabled) ||
		(syncList[displayName].isOutOfLoginSync && loginSyncEnabled) ||
		(syncList[displayName].isOutOfIdleSync && idleNotifierEnabled)
	);
}

export function checkIdleStateSyncStatus(polyClients) {
	if (!isEnabled) return;

	for (const client of Object.values(polyClients)) {
		console.log('DEX ' + client.index);
		if (client.statsInfo) {
			const syncEntry = syncList[client.statsInfo.displayName];
			if (!syncEntry) continue;

			console.log('OUR STATE ' + client.syncInfo.playerAnimation);
			if (client.syncInfo.playerAnimation == -1) {
				if (!syncEntry.notifiedLoginSyncLoss) {
					syncEntry.notifiedIdleSyncLoss = true;
					syncEntry.notifiedIdleSyncLossTime = Date.now();
				} else {
					if (
						Date.now() >
						syncEntry.notifiedIdleSyncLossTime + idleNotifierThreshold
					) {
						syncEntry.isOutOfIdleSync = true;
						syncEntry.notifiedIdleSyncLoss = false;
						syncEntry.notifiedIdleSyncLossTime = null;
					}
				}
			} else {
				syncEntry.isOutOfIdleSync = false;
				syncEntry.notifiedIdleSyncLoss = false;
				syncEntry.notifiedIdleSyncLossTime = null;
			}
		}
	}
}

export function checkDialogueMatchesSyncStatus(polyClients) {
	if (!isEnabled) return;
	const allClientDialogueState = [];

	for (const client of Object.values(polyClients)) {
		if (client.index > -1 && client.syncInfo) {
			allClientDialogueState.push(client.syncInfo.chatDialogue);
		}
	}

	const chatDialogueCounts = {};
	allClientDialogueState.forEach(function (x) {
		chatDialogueCounts[x] = (chatDialogueCounts[x] || 0) + 1;
	});
	let highest = 0;
	let majorityState = null;

	//more than 1 position. out of sync.
	//grab the count with the highest value. this is majority and we will flag all non majority clients
	for (const key in chatDialogueCounts) {
		const count = chatDialogueCounts[key];
		if (count > highest) {
			highest = count;
			majorityState = key;
		}
	}

	for (const client of Object.values(polyClients)) {
		if (client.statsInfo) {
			const syncEntry = syncList[client.statsInfo.displayName];
			if (!syncEntry) continue;
			if (client.syncInfo.chatDialogue !== majorityState) {
				if (!syncEntry.notifiedChatDialogueSyncLoss) {
					syncEntry.notifiedChatDialogueSyncLoss = true;
					syncEntry.notifiedChatDialogueSyncLossTime = Date.now();
				} else {
					if (
						Date.now() >
						syncEntry.notifiedChatDialogueSyncLossTime + syncLossTimerThreshold
					) {
						syncEntry.isOutOfChatDialogueSync = true;
						syncEntry.notifiedChatDialogueSyncLoss = false;
						syncEntry.notifiedChatDialogueSyncLossTime = null;
						syncEntry.notifiedChatDialogueSyncType = SYNC_TYPES.INTERFACE;
					}
				}
			} else {
				syncEntry.isOutOfChatDialogueSync = false;
				syncEntry.notifiedChatDialogueSyncLoss = false;
				syncEntry.notifiedChatDialogueSyncLossTime = null;
				syncEntry.notifiedChatDialogueSyncType = null;
			}
		}
	}
}
export function checkEquipmentMatchesSyncStatus(
	polyClients,
	stackedItemQuantityMatters
) {
	stackedItemQuantityMatters = false;
	if (!isEnabled) return;
	const allClientInventoryState = [];

	for (const client of Object.values(polyClients)) {
		if (client.index > -1 && client.syncInfo) {
			const itemMap = {};
			//slot order is irrelevant, we just need to map the amount of items and their quantities. duplicate item ids should increment quantity
			for (const item of client.syncInfo.equipmentItems) {
				if (!itemMap[item.itemId]) {
					stackedItemQuantityMatters
						? (itemMap[item.itemId] = parseInt(item.itemQuantity))
						: (itemMap[item.itemId] = parseInt(1));
				} else {
					stackedItemQuantityMatters
						? (itemMap[item.itemId] += parseInt(item.itemQuantity))
						: (itemMap[item.itemId] += parseInt(1));
				}
			}
			const inventorySortedItems = JSON.stringify(sortedObject(itemMap));
			allClientInventoryState.push(inventorySortedItems);
			client.syncInfo.equipmentSortedItems = inventorySortedItems;
		}
	}
	//get the majority inventory

	const inventoryCounts = {};
	allClientInventoryState.forEach(function (x) {
		inventoryCounts[x] = (inventoryCounts[x] || 0) + 1;
	});

	let highest = 0;
	let majorityState = null;

	//more than 1 position. out of sync.
	//grab the count with the highest value. this is majority and we will flag all non majority clients
	for (const key in inventoryCounts) {
		const count = inventoryCounts[key];
		if (count > highest) {
			highest = count;
			majorityState = key;
		}
	}

	for (const client of Object.values(polyClients)) {
		if (client.statsInfo) {
			const syncEntry = syncList[client.statsInfo.displayName];
			if (!syncEntry) continue;
			if (client.syncInfo.equipmentSortedItems != majorityState) {
				if (!syncEntry.notifiedEquipmentSyncLoss) {
					syncEntry.notifiedEquipmentSyncLoss = true;
					syncEntry.notifiedEquipmentSyncLossTime = Date.now();
				} else {
					if (
						Date.now() >
						syncEntry.notifiedEquipmentSyncLossTime + syncLossTimerThreshold
					) {
						syncEntry.isOutOfEquipmentSync = true;
						syncEntry.notifiedEquipmentSyncLoss = false;
						syncEntry.notifiedEquipmentSyncLossTime = null;
						syncEntry.notifiedEquipmentSyncType = SYNC_TYPES.INTERFACE;
					}
				}
			} else {
				syncEntry.isOutOfEquipmentSync = false;
				syncEntry.notifiedEquipmentSyncLoss = false;
				syncEntry.notifiedEquipmentSyncLossTime = null;
				syncEntry.notifiedEquipmentSyncType = null;
			}
		}
	}
}
export function checkInventoryMatchesSyncStatus(
	polyClients,
	stackedItemQuantityMatters
) {
	stackedItemQuantityMatters = false;
	if (!isEnabled) return;
	const allClientInventoryState = [];

	for (const client of Object.values(polyClients)) {
		if (client.index > -1 && client.syncInfo) {
			const itemMap = {};
			//slot order is irrelevant, we just need to map the amount of items and their quantities. duplicate item ids should increment quantity
			for (const item of client.syncInfo.inventoryItems) {
				if (!itemMap[item.itemId]) {
					stackedItemQuantityMatters
						? (itemMap[item.itemId] = parseInt(item.itemQuantity))
						: (itemMap[item.itemId] = parseInt(1));
				} else {
					stackedItemQuantityMatters
						? (itemMap[item.itemId] += parseInt(item.itemQuantity))
						: (itemMap[item.itemId] += parseInt(1));
				}
			}
			const inventorySortedItems = JSON.stringify(sortedObject(itemMap));
			allClientInventoryState.push(inventorySortedItems);
			client.syncInfo.inventorySortedItems = inventorySortedItems;
		}
	}
	//get the majority inventory

	const inventoryCounts = {};
	allClientInventoryState.forEach(function (x) {
		inventoryCounts[x] = (inventoryCounts[x] || 0) + 1;
	});

	let highest = 0;
	let majorityState = null;

	//more than 1 position. out of sync.
	//grab the count with the highest value. this is majority and we will flag all non majority clients
	for (const key in inventoryCounts) {
		const count = inventoryCounts[key];
		if (count > highest) {
			highest = count;
			majorityState = key;
		}
	}

	for (const client of Object.values(polyClients)) {
		if (client.statsInfo) {
			const syncEntry = syncList[client.statsInfo.displayName];
			if (!syncEntry) continue;
			if (client.syncInfo.inventorySortedItems != majorityState) {
				if (!syncEntry.notifiedInventorySyncLoss) {
					syncEntry.notifiedInventorySyncLoss = true;
					syncEntry.notifiedInventorySyncLossTime = Date.now();
				} else {
					if (
						Date.now() >
						syncEntry.notifiedInventorySyncLossTime + syncLossTimerThreshold
					) {
						syncEntry.isOutOfInventorySync = true;
						syncEntry.notifiedInventorySyncLoss = false;
						syncEntry.notifiedInventorySyncLossTime = null;
						syncEntry.notifiedInventorySyncType = SYNC_TYPES.INTERFACE;
					}
				}
			} else {
				syncEntry.isOutOfInventorySync = false;
				syncEntry.notifiedInventorySyncLoss = false;
				syncEntry.notifiedInventorySyncLossTime = null;
				syncEntry.notifiedInventorySyncType = null;
			}
		}
	}
}
function sortedObject(unordered) {
	return Object.keys(unordered)
		.sort()
		.reduce((obj, key) => {
			obj[key] = unordered[key];
			return obj;
		}, {});
}

export function checkLoginStateSyncStatus(polyClients) {
	if (!isEnabled) return;
	const allClientLoggedInState = [];

	for (const client of Object.values(polyClients)) {
		if (client.index > -1 && client.syncInfo) {
			allClientLoggedInState.push(client.syncInfo.isLoggedIn);
		}
	}

	const loginStateCounts = {};
	allClientLoggedInState.forEach(function (x) {
		loginStateCounts[x] = (loginStateCounts[x] || 0) + 1;
	});
	let highest = 0;
	let majorityState = null;

	//more than 1 position. out of sync.
	//grab the count with the highest value. this is majority and we will flag all non majority clients
	for (const key in loginStateCounts) {
		const count = loginStateCounts[key];
		if (count > highest) {
			highest = count;
			majorityState = key;
		}
	}

	console.log('majority ' + majorityState);

	for (const client of Object.values(polyClients)) {
		console.log('DEX ' + client.index);
		if (client.statsInfo) {
			const syncEntry = syncList[client.statsInfo.displayName];
			if (!syncEntry) continue;

			console.log('OUR STATE ' + client.syncInfo.isLoggedIn);
			if (client.syncInfo.isLoggedIn !== majorityState) {
				if (!syncEntry.notifiedLoginSyncLoss) {
					syncEntry.notifiedLoginSyncLoss = true;
					syncEntry.notifiedLoginSyncLossTime = Date.now();
				} else {
					if (
						Date.now() >
						syncEntry.notifiedLoginSyncLossTime + syncLossTimerThreshold
					) {
						syncEntry.isOutOfLoginSync = true;
						syncEntry.notifiedLoginSyncLoss = false;
						syncEntry.notifiedLoginSyncLossTime = null;
						syncEntry.notifiedLoginSyncType = SYNC_TYPES.LOGIN;
					}
				}
			} else {
				syncEntry.isOutOfLoginSync = false;
				syncEntry.notifiedLoginSyncLoss = false;
				syncEntry.notifiedLoginSyncLossTime = null;
				syncEntry.notifiedLoginSyncType = null;
			}
		}
	}
}

export function checkWidgetOpenSyncStatus(polyClients) {
	if (!isEnabled) return;
	const allClientWidgetOpenState = [];

	for (const client of Object.values(polyClients)) {
		if (client.index > -1 && client.syncInfo) {
			allClientWidgetOpenState.push(client.syncInfo.isWidgetOpen);
		}
	}

	const widgetOpenCounts = {};
	allClientWidgetOpenState.forEach(function (x) {
		widgetOpenCounts[x] = (widgetOpenCounts[x] || 0) + 1;
	});
	let highest = 0;
	let majorityState = null;

	//more than 1 position. out of sync.
	//grab the count with the highest value. this is majority and we will flag all non majority clients
	for (const key in widgetOpenCounts) {
		const count = widgetOpenCounts[key];
		if (count > highest) {
			highest = count;
			majorityState = key;
		}
	}

	for (const client of Object.values(polyClients)) {
		if (client.statsInfo) {
			const syncEntry = syncList[client.statsInfo.displayName];
			if (!syncEntry) continue;
			if (client.syncInfo.isWidgetOpen !== majorityState) {
				if (!syncEntry.notifiedWidgetSyncLoss) {
					syncEntry.notifiedWidgetSyncLoss = true;
					syncEntry.notifiedWidgetSyncLossTime = Date.now();
				} else {
					if (
						Date.now() >
						syncEntry.notifiedWidgetSyncLossTime + syncLossTimerThreshold
					) {
						syncEntry.isOutOfWidgetSync = true;
						syncEntry.notifiedWidgetSyncLoss = false;
						syncEntry.notifiedWidgetSyncLossTime = null;
						syncEntry.notifiedWidgetSyncType = SYNC_TYPES.INTERFACE;
					}
				}
			} else {
				syncEntry.isOutOfWidgetSync = false;
				syncEntry.notifiedWidgetSyncLoss = false;
				syncEntry.notifiedWidgetSyncLossTime = null;
				syncEntry.notifiedWidgetSyncType = null;
			}
		}
	}
}
export function checkPositionSyncStatus(polyClients) {
	if (!isEnabled) return;
	const allClientLocations = [];

	for (const client of Object.values(polyClients)) {
		if (client.index > -1) {
			allClientLocations.push({
				x: client.worldLocationX,
				y: client.worldLocationY,
				z: client.mapLocationZ,
			});
		}
	}

	const worldLocationCounts = {};
	allClientLocations.forEach(function (x) {
		worldLocationCounts[JSON.stringify(x)] =
			(worldLocationCounts[JSON.stringify(x)] || 0) + 1;
	});
	let highest = 0;
	let majorityLocation = null;

	//more than 1 position. out of sync.
	//grab the count with the highest value. this is majority and we will flag all non majority clients
	for (const key in worldLocationCounts) {
		const worldLocation = worldLocationCounts[key];
		if (worldLocation > highest) {
			highest = worldLocation;
			majorityLocation = key;
		}
	}

	//if this is still existing for more than 2s, then trigger out of sync

	for (const client of Object.values(polyClients)) {
		if (client.index > -1) {
			const worldLocation = JSON.stringify({
				x: client.worldLocationX,
				y: client.worldLocationY,
				z: client.mapLocationZ,
			});
			if (client.statsInfo) {
				const syncEntry = syncList[client.statsInfo.displayName];
				if (!syncEntry) continue;
				const worldLocationObj = JSON.parse(worldLocation);
				const majorityLocationObj = JSON.parse(majorityLocation);
				const deltaX = Math.abs(worldLocationObj.x - majorityLocationObj.x);
				const deltaY = Math.abs(worldLocationObj.y - majorityLocationObj.y);
				if (
					Math.sqrt(deltaX * deltaX + deltaY * deltaY) > positionRadius ||
					worldLocationObj.z != majorityLocationObj.z
				) {
					//check if world location is within x tiles of majority world location

					if (!syncEntry.notifiedPositionSyncLoss) {
						syncEntry.notifiedPositionSyncLoss = true;
						syncEntry.notifiedPositionSyncLossTime = Date.now();
					} else {
						if (
							Date.now() >
							syncEntry.notifiedPositionSyncLossTime + syncLossTimerThreshold
						) {
							syncEntry.isOutOfPositionSync = true;
							syncEntry.notifiedPositionSyncLoss = false;
							syncEntry.notifiedPositionSyncLossTime = null;
							syncEntry.notifiedPositionSyncType = SYNC_TYPES.POSITION;
						}
					}
				} else {
					syncEntry.isOutOfPositionSync = false;
					syncEntry.notifiedPositionSyncLoss = false;
					syncEntry.notifiedPositionSyncLossTime = null;
					syncEntry.notifiedPositionSyncType = null;
				}
			}
		}
	}
}
