import React, { Fragment, useState } from 'react';
import * as SocketConnection from '../utils/SocketConnection';
import prayerIcon from '../assets/Prayer_icon.png';
import hitpointsIcon from '../assets/Hitpoints_icon.png';
import osboxLogo from '../assets/osbox.png';
import ControlButtons from '../views/ControlButtons';
import { useHistory } from 'react-router-dom';
const Navbar = (props) => {
	const history = useHistory();
	const [serverAddress, setServerAddress] = useState(null);
	React.useEffect(() => {
		
		setServerAddress(props.serverAddress);
	}, [history, serverAddress]);
	function handleLogout() {
		SocketConnection.clearEmail();
		SocketConnection.clearPassword();
		history.push('/login');
	}
	return (
		<div className="navbar">
			<nav className="navigation">
				<ul>
					<li>
						<img className="navbar-logo" src={osboxLogo}></img>
					</li>
					<li>
						<h3 className="navbar-title">Control Dashboard</h3>
					</li>
					|
					<li>
						<h3 className="navbar-title server-address">
							{serverAddress}
							<div className="server-online-notifier"></div>
						</h3>
					</li>
					<li>
						<h3 className="navbar-title logout-title" onClick={handleLogout}>
							Log out
						</h3>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Navbar;
